import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DriveFolderFormDataType, DRIVE_FOLDER_FORM_SCHEMA } from 'app/constants';

import Button from '../Button';
import { Form, ImageField, InputField } from '../Form';
import Modal from '../Modal';

interface Props {
  visible: boolean;
  loading?: boolean;
  initialValue?: DriveFolderFormDataType;
  hiddenImageField?: boolean;
  onClose: () => void;
  onSubmit: (data: DriveFolderFormDataType) => Promise<void>;
}

export const DriveFolderFormModal = ({
  visible,
  loading,
  initialValue,
  hiddenImageField = false,
  onClose,
  onSubmit,
}: Props) => {
  const methods = useForm<DriveFolderFormDataType>({
    defaultValues: {
      name: '',
      image: [],
    },
    resolver: zodResolver(DRIVE_FOLDER_FORM_SCHEMA),
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(initialValue);
  }, [initialValue, reset]);

  return (
    <Modal
      title="篩選"
      visible={visible}
      onClose={() => {
        onClose();
        reset({
          name: '',
          image: [],
        });
      }}
      panelClassName="relative"
      containerClassName="size-full p-0"
      size="xl"
    >
      <Form
        methods={methods}
        paddingBottom={16}
      >
        <InputField
          name="name"
          label="Folder 名稱"
          containerClassName="col-span-2"
        />
        {!hiddenImageField && (
        <ImageField
          name="image"
          label="資料夾封面(選填)"
          className="col-span-2"
        />
        )}

        <div className="col-start-2 flex justify-end">
          <Button
            variant="contained"
            color="primary"
            containerStyle="px-[12px] py-[8px]"
            onClick={handleSubmit((data) => { onSubmit(data); reset(); })}
            loading={loading}
          >
            提交
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

import dayjs from 'dayjs';
import { z } from 'zod';

import {
  DisplayPermission, NotificationPublishType, PermissionUserType,
} from 'app/models/CommerceItem';
import { FormPreviewItem } from 'app/models/Common';
import { SurveyQuestionType } from 'app/models/Survey';
import {
  MemberLevelType, UserJobTitleType,
} from 'app/models/User';

export const COMMERCE_ITEM_PERMISSION_FORM_SCHEMA = z.object({
  CommercePermissions: z
    .array(
      z.object({
        commercePermissionId: z
          .number()
          .optional(),
        userType: z
          .custom<PermissionUserType>()
          .nullable(),
        memberLevel: z
          .custom<MemberLevelType>()
          .nullable(),
        userTag: z
          .string()
          .nullable(),
        branchName: z
          .string()
          .nullable(),
        family: z
          .string()
          .nullable(),
        jobTitle: z
          .custom<UserJobTitleType>()
          .nullable(),
      }),
    ),
  displayPermission: z
    .custom<DisplayPermission>(),
});

export type CommerceItemPermissionFormDataType = z.infer<typeof COMMERCE_ITEM_PERMISSION_FORM_SCHEMA>;

export const INITIALIZED_COMMERCE_ITEM_PERMISSION: CommerceItemPermissionFormDataType['CommercePermissions'][number] = {
  commercePermissionId: undefined,
  userType: null,
  memberLevel: null,
  userTag: null,
  branchName: null,
  family: null,
  jobTitle: null,

};

export const COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA = z
  .object({
    publishType: z
      .custom<NotificationPublishType>(),
    scheduledTime: z
      .date()
      .nullable(),
    title: z
      .string(),
    content: z
      .string()
      .nullable(),
    scheduledNotificationId: z
      .number()
      .optional(),
  })
  .refine(({ publishType, scheduledTime }) => publishType === 'instant' || scheduledTime, {
    message: '預約發佈時必須填寫發佈日期',
    path: ['scheduledTime'],
  })
  .refine(({ title, content }) => {
    if (content) {
      return !!title;
    }
    return true;
  }, { message: '必填', path: ['title'] });

export type CommerceItemNotificationInfoFormDataType = z.infer<typeof COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA>;

export const INITIALIZED_COMMERCE_ITEM_NOTIFICATION_INFO : CommerceItemNotificationInfoFormDataType = {
  publishType: 'instant',
  scheduledTime: null,
  title: '',
  content: '',
};

export const COMMERCE_ITEM_COMMON_FORM_SCHEMA = z
  .object({
    permission: COMMERCE_ITEM_PERMISSION_FORM_SCHEMA,
    pushNotification: COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA,
  });

export type CommerceItemCommonFormDataType = z.infer<typeof COMMERCE_ITEM_COMMON_FORM_SCHEMA>;

export const COMMERCE_ITEM_CHECK_IN_OUT_FORM_SCHEMA = z
  .object({
    checkInDateTime: z
      .date()
      .nullable(),
    checkOutDateTime: z
      .date()
      .nullable(),
  })
  .refine(({ checkInDateTime }) => checkInDateTime, { message: '必填', path: ['checkInDateTime'] })
  .refine(
    ({ checkInDateTime, checkOutDateTime }) => !checkInDateTime
    || !checkOutDateTime
    || dayjs(checkInDateTime).isBefore(checkOutDateTime),
    { message: '出席時間要比離開時間早', path: ['checkInDateTime'] },
  );

export type CommerceItemCheckInOutFormDataType = z.infer<typeof COMMERCE_ITEM_CHECK_IN_OUT_FORM_SCHEMA>;

export const IMAGE_RELATED_FIELD = ['coverPhoto', 'image'];

export const RICH_TEXT_RELATED_FIELD = ['description', 'caution', 'paidReminder'];

export const OBJECT_RELATED_FIELD = ['CommercePurchaseOptions', 'CommerceSchedules', 'ScheduledNotification'];

export const REDEMPTION_FORM_SCHEMA = z
  .object({
    commerceApplicationOptions: z
      .array(
        z.object({
          max: z.number(),
          numberOfOption: z
            .string()
            .regex(/^\d+$/, '請輸入正整數')
            .or(z.literal('')),
          commercePurchaseOptionId: z
            .number(),
        })
          .refine(({ max, numberOfOption }) => !numberOfOption || parseInt(numberOfOption, 10) <= max, {
            message: '超出可換領數量',
            path: ['numberOfOption'],
          }),
      ),
  });

export type RedemptionFormDataType = z.infer<typeof REDEMPTION_FORM_SCHEMA>;

export const QuestionSchema = z.object({
  questionType: z.custom<SurveyQuestionType>(),
  isRequired: z.boolean(),
  questionId: z.number(),
  commercePurchaseOptionId: z.number().optional(),
  ordering: z.number().default(0).optional(),
  text: z.string(),
  minAttachment: z.number().default(0),
  attachments: z
    .array(
      z.instanceof(File)
        .or(z.custom<FormPreviewItem>()),
      {
        required_error: '必填',
      },
    ),
  optionAnswers: z.record(z.string(), z.boolean()),
  optionalAnswersNeedAdditionalInputs: z.record(z.string(), z.boolean()),
  optionAdditionalInputs: z.array(z.object({
    questionOptionId: z.number(),
    additionalInput: z.string(),
  })),
})
  .refine(
    // 輸入題 or 電郵
    (response) => !(
      (response.questionType === '輸入題' || response.questionType === '電郵')
      && response.isRequired && !response.text
    ),
    {
      message: '必填',
      path: ['text'],
    },
  )
  .refine(
    // 電郵 format checking
    (response) => {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      return !(response.questionType === '電郵' && !response.text.match(emailRegex));
    },
    {
      message: '請輸入正確電郵地址',
      path: ['text'],
    },
  )
  .refine(
  // 附件題
    (response) => !(response.questionType === '附件題'
  && (response.isRequired || !!response.minAttachment)
  && response.attachments
  && response.attachments.length < (response.minAttachment ? response.minAttachment : 1)),
    (response) => ({
      message: `請上載最少${response.minAttachment ? response.minAttachment : 1}個檔案`,
      path: ['attachments'],
    }),
  )
  .refine(
  // 程度題 && 單選選擇題 && 多選選擇題
    (response) => !(
      (response.questionType === '程度題'
    || response.questionType === '單選選擇題'
    || response.questionType === '多選選擇題'
      )
  && response.isRequired
  && response.optionAnswers
  && Object.values(response.optionAnswers).every((ans) => !ans)
    ),
    {
      message: '必填',
      path: ['optionAnswers'],
    },
  )
  .superRefine(
  // 單選選擇題 && 多選選擇題 additional Input checking
    ({
      questionType,
      optionAnswers,
      optionalAnswersNeedAdditionalInputs,
      optionAdditionalInputs,
    }, ctx) => {
      if ((questionType === '單選選擇題' || questionType === '多選選擇題')) {
        optionAdditionalInputs.forEach(({
          questionOptionId,
          additionalInput,
        }, index) => {
          if (
            optionAnswers[questionOptionId]
          && optionalAnswersNeedAdditionalInputs[questionOptionId]
          && !additionalInput
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: '必填',
              path: [`optionAdditionalInputs[${index}].additionalInput`],
            });
          }
        });
      }
    },
  );

export type CommerceApplicationSurveyQuestionFormDataType = z.infer<typeof QuestionSchema>;

export const COMMERCE_ITEM_MANUAL_APPLICATION_FORM_SCHEMA = z
  .object({
    userId: z
      .number()
      .refine((userId) => userId > 0, {
        message: '必填',
      }),
    CommerceApplicationOptions: z
      .array(z
        .object({
          commerceApplicationOptionId: z
            .number()
            .optional(),
          commercePurchaseOptionId: z
            .number()
            .refine((commercePurchaseOptionId) => commercePurchaseOptionId > 0, {
              message: '必填',
            }),
          numberOfOption: z
            .string()
            .regex(/^[1-9]\d*$/, '請輸入正整數')
            .or(z.literal('')),
          point: z
            .number(),
          cash: z
            .number(),
        })),
    SurveyResponses: z
      .array(z
        .object({
          surveyResponseTitle: z
            .string()
            .nullish(),
          commercePurchaseOptionId: z
            .number()
            .optional(),
          commerceApplicationId: z
            .number()
            .nullish(),
          commerceItemId: z
            .number(),
          commerceAttendeeId: z
            .number()
            .nullish(),
          userId: z
            .number()
            .nullish(),
          preAgentId: z
            .number()
            .nullish(),
          surveyId: z
            .number()
            .nullish(),
          preAgentSurveyId: z
            .number()
            .nullish(),
          commerceScheduleApplicationId: z
            .number()
            .optional(),
          responses: z
            .array(QuestionSchema),
        })),
    CommerceAttendees: z
      .array(z
        .object({
          commerceAttendeeId: z
            .number()
            .optional(),
          userId: z
            .number()
            .optional(),
          preAgentId: z
            .number()
            .optional(),
        })),
  });

export type CommerceItemManualApplicationFormDataType = z.infer<typeof COMMERCE_ITEM_MANUAL_APPLICATION_FORM_SCHEMA>;

import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { adminLogin } from 'app/apis/AdminServices';
import Logo from 'app/assets/images/logo.png';
import Button from 'app/components/Button';
import { Form, InputField } from 'app/components/Form';
import { Image } from 'app/components/Image';
import { APP_NAME } from 'app/configs';
import { LOGIN_FORM_SCHEMA, LoginFormDataType } from 'app/constants';
import { useAuthStore } from 'app/stores';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { setAdminProfile } = useAuthStore();

  const methods = useForm<LoginFormDataType>({
    defaultValues: {
      loginName: '',
      password: '',
    },
    resolver: zodResolver(LOGIN_FORM_SCHEMA),
  });

  const onSubmit = async ({ loginName, password }: LoginFormDataType) => {
    try {
      setLoading(true);
      const res = await adminLogin({ loginName, password, rememberMe: true });
      navigate('/');
      setAdminProfile(res.admin);
    } catch (err) {
      methods.setError('loginName', {
        type: 'manual',
        message: '登入名稱或密碼錯誤',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen w-full items-center justify-center bg-bg-primary">
      <div className="w-full rounded-[5px] border bg-white p-[24px] md:w-[500px]">
        <Image src={Logo} alt="logo" width={150} />
        <h1 className="mt-4 text-[20px] font-semibold text-text-primary">
          {APP_NAME}
        </h1>
        <p className="mt-1 text-[14px] font-normal text-text-light">
          請輸入登入名稱及密碼!
        </p>

        <Form
          methods={methods}
          className="mt-4"
          innerClassName="!p-0 !pt-[30px]"
        >
          <div className="col-span-2 flex flex-col gap-4">
            <InputField
              label="登入名稱"
              placeholder="登入名稱"
              name="loginName"
              type="text"
            />
            <InputField
              label="密碼"
              placeholder="密碼"
              name="password"
              type="password"
            />
            <div className="mobile:mb-4 relative mb-2.5">
              <Button
                color="primary"
                type="submit"
                loading={loading}
                disabled={loading}
                variant="contained"
                containerStyle="px-4 py-[8px] text-white rounded-md w-full flex items-center justify-center h-[40px]"
                onClick={methods.handleSubmit(onSubmit)}
              >
                登入
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

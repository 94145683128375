import { Link } from 'react-router-dom';

import { ROUTES } from 'app/constants';

export const NotFoundPage = () => (
  <div>
    <h2>404 - Page Not Found</h2>
    <Link to={ROUTES.HOME}>Home</Link>
  </div>
);

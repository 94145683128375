import { create } from 'zustand';

export type CommerceItemStore = {
  newsSubmittedCount: number;
  productSubmittedCount: number;
  eventSubmittedCount: number;
  courseSubmittedCount: number;
  updateNewsSubmittedCount: (count: number) => void;
  updateProductSubmittedCount: (count: number) => void;
  updateEventSubmittedCount: (count: number) => void;
  updateCourseSubmittedCount: (count: number) => void;
};

export const useCommerceItemStore = create<CommerceItemStore>()(
  (set) => ({
    newsSubmittedCount: 0,
    productSubmittedCount: 0,
    eventSubmittedCount: 0,
    courseSubmittedCount: 0,
    updateNewsSubmittedCount: (count) => set((prev) => ({
      ...prev,
      newsSubmittedCount: count,
    })),
    updateProductSubmittedCount: (count) => set((prev) => ({
      ...prev,
      productSubmittedCount: count,
    })),
    updateEventSubmittedCount: (count) => set((prev) => ({
      ...prev,
      eventSubmittedCount: count,
    })),
    updateCourseSubmittedCount: (count) => set((prev) => ({
      ...prev,
      courseSubmittedCount: count,
    })),
  }),
);

import { AdminResponse } from './Admin';
import { TimeStamp } from './Common';
import { UserResponse } from './User';

export interface PerformanceResponse extends TimeStamp {
  performanceId: number;
  userId: number;
  amount: number;
  status: PerformanceStatusType;
  approvedBy?: number | null;
  User?: UserResponse | null;
  Admin?: AdminResponse | null;
}

export enum PerformanceStatus {
  Unverified = '未認證',
  Verified = '已認證',
  Rejected = '被拒絕',
}

export type PerformanceStatusType = keyof typeof PerformanceStatus;

export const PERFORMANCE_LIST_SORT_OPTION_MAP = {
  userId: '會員編號',
  createdAt: '建立日期',
  amount: '金額',
  'User.displayName': '會員名稱',
  'Admin.displayName': '批准者',
};

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useMemo } from 'react';
import { IoClose } from 'react-icons/io5';

import Button from 'app/components/Button';
import cn from 'app/utils/ClassName';

interface Props {
  visible: boolean;
  title: string;
  children?: React.ReactNode;
  onClose: () => void;
  submitOnClick?: () => void;
  loading?: boolean;
  isConfirmButtonDisable?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  panelClassName?: string;
  containerClassName?: string;
}

const Modal = ({
  visible,
  title,
  children,
  onClose,
  submitOnClick,
  loading,
  isConfirmButtonDisable,
  size,
  panelClassName,
  containerClassName,
}: Props) => {
  const DialogMaxWidth = useMemo(() => {
    switch (size) {
      case 'sm':
        return 'max-w-sm';
      case 'md':
        return 'max-w-md';
      case 'lg':
        return 'max-w-lg';
      case 'xl':
        return 'max-w-xl';
      case '2xl':
        return 'max-w-2xl';
      case '3xl':
        return 'max-w-3xl';
      default:
        return 'max-w-md';
    }
  }, [size]);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'flex size-full flex-col overflow-visible rounded-[3px] bg-white text-left align-middle shadow-xl transition-all p-4',
                  DialogMaxWidth,
                  panelClassName,
                )}
              >
                <button
                  type="button"
                  disabled={loading}
                  className="absolute right-[20px] top-[20px]"
                  onClick={onClose}
                >
                  <IoClose className="size-[25px]" color="#A7A7A7" />
                </button>
                <div className={cn('overflow-hidden p-[16px]', containerClassName)}>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  {children}
                </div>
                {submitOnClick ? (
                  <div className="bottom-[10px] left-0 flex w-full justify-end gap-x-[20px] border-t border-t-border-brand px-[16px] pt-[16px]">
                    <Button
                      variant="contained"
                      color="attention"
                      containerStyle="px-4 py-1"
                      onClick={onClose}
                      disabled={loading}
                    >
                      <div className="flex items-center justify-center gap-1">
                        <span>取消</span>
                      </div>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      containerStyle="px-4 py-1"
                      onClick={submitOnClick}
                      loading={loading}
                      disabled={isConfirmButtonDisable}
                    >
                      <div className="flex items-center justify-center gap-1">
                        <span>確定</span>
                      </div>
                    </Button>
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;

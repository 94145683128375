import qs from 'qs';

import {
  DriveFileResponse, DriveShareToken, DriveType, FolderType, GetOneFileTypeRes,
} from 'app/models/Drive';

import Axios from './index';

export interface FileParams {
  folderType?: FolderType;
  driveType: DriveType;
  shopId?: string | number;
  path?: string;
}

export interface SearchFileParams extends FileParams {
  q: string;
}

/**
 *  List folder (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive)
 */

export const listFolder = async (params: FileParams) => {
  const { data } = await Axios.get<DriveFileResponse[]>('/drive', {
    params,
  });
  return data;
};

/**
 *  Search File inside folder (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/search)
 */

export const searchFileInsideFolder = async (params: SearchFileParams) => {
  const { data } = await Axios.get<DriveFileResponse[]>('/drive/search', {
    params,
  });
  return data;
};

/**
 *  Get one file (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/file)
 */

export const getOneFile = async (params: FileParams) => {
  const { data } = await Axios.get<DriveFileResponse>('/drive/file', {
    params,
  });
  return data;
};

export const getFileIcon = async (params: FileParams) => {
  const { data } = await Axios.get('/drive/file', {
    params,
  });
  return data;
};

/**
 *  Delete one file (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/drive/file)
 */

export const deleteOneFile = async (params: FileParams) => {
  await Axios.delete('/drive/file', {
    params,
  });
};

/**
 *  Get one file type (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/fileType)
 */

export const getOneFileType = async (params: FileParams) => {
  const { data } = await Axios.get<GetOneFileTypeRes>('/drive/fileType', {
    params,
  });
  return data;
};

export interface CopyDocumentParams {
  source: FileParams;
  dest: FileParams;
}

/**
 *  Copy File/Folder folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/copy)
 */

export const copyDocument = async (params: CopyDocumentParams) => {
  await Axios.post('/drive/copy', params);
};

/**
 *  Create a new folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/createFolder)
 */

export const createNewFolder = async (params: FileParams) => {
  await Axios.post('/drive/createFolder', {}, { params });
};

/**
 *  Delete one file (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/drive/file)
 */

export const deleteDocument = async (params: FileParams) => {
  await Axios.delete('drive/file', { params });
};

export interface MoveDocumentParams {
  source: FileParams;
  dest: FileParams;
}

/**
 *  Move/ Rename File/Folder folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/rename)
 */

export const moveRenameDocument = async (params: MoveDocumentParams) => {
  await Axios.post('/drive/rename', params);
};

export interface GetShareFolderListParams extends FileParams {
  shareToken: string;
}

/**
 *  List share folder (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/share)
 */

export const getShareFolderList = async (params: GetShareFolderListParams) => {
  const { data } = await Axios.get<DriveFileResponse[]>('/drive/share', { params });
  return data;
};

/**
 *  Share Folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/share)
 */

export const getShareToken = async (params: FileParams) => {
  const { data } = await Axios.post<DriveShareToken>('/drive/share', params);
  return data;
};

export interface ShareTokenFileParams extends FileParams {
  shareToken: string;
}

/**
 *  Get one share file type (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/share/fileType)
 */

export const getShareTokenFileParams = async (params: ShareTokenFileParams) => {
  const { data } = await Axios.get<GetOneFileTypeRes>('/drive/share/fileType', {
    params,
  });
  return data;
};

export const getDriveFileURL = (
  params: FileParams,
) => `${Axios.defaults.baseURL}drive/file?${qs.stringify(params)}`;

/**
 * upload one file to drive (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/upload)
 */

export const uploadOneFileToDrive = async (
  params: FileParams,
  file: FormData,
) => {
  const { data } = await Axios.post<DriveFileResponse>('/drive/upload', file, {
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 1000 * 60 * 50,
  });
  return data;
};

export const SHOP_MODE_ROUTES = (shopId: number | string) => {
  const prefix = `/shop/${shopId}`;
  return ({
  // events
    EVENTS: `${prefix}/events`,
    EVENTS_LIST: `${prefix}/events/list`,
    EVENTS_NEW: `${prefix}/events/list/new`,
    EVENTS_EDIT: (id: number) => `${prefix}/events/list/edit/${id}`,
    EVENTS_COPY: (id: number) => `${prefix}/events/list/copy/${id}`,
    EVENTS_VIEW: (id: number) => `${prefix}/events/list/view/${id}`,

    // shop
    SHOP: `${prefix}/shop`,
    SHOP_LIST: `${prefix}/shop/list`,
    SHOP_NEW: `${prefix}/shop/list/new`,
    SHOP_EDIT: (id: number) => `${prefix}/shop/list/edit/${id}`,

    // News
    NEWS_LIST: `${prefix}/news/list`,
    NEWS_NEW: `${prefix}/news/list/new`,
    NEWS_EDIT: (id: number) => `${prefix}/news/list/edit/${id}`,
    NEWS_COPY: (id: number) => `${prefix}/news/list/copy/${id}`,
    NEWS_VIEW: (id: number) => `${prefix}/news/list/view/${id}`,

    // Product
    PRODUCT_LIST: `${prefix}/product/list`,
    PRODUCT_NEW: `${prefix}/product/list/new`,
    PRODUCT_EDIT: (id: number) => `${prefix}/product/list/edit/${id}`,
    PRODUCT_COPY: (id: number) => `${prefix}/product/list/copy/${id}`,
    PRODUCT_VIEW: (id: number) => `${prefix}/product/list/view/${id}`,

    // Course
    COURSE_LIST: `${prefix}/course/list`,
    COURSE_NEW: `${prefix}/course/list/new`,
    COURSE_EDIT: (id: number) => `${prefix}/course/list/edit/${id}`,
    COURSE_COPY: (id: number) => `${prefix}/course/list/copy/${id}`,
    COURSE_VIEW: (id: number) => `${prefix}/course/list/view/${id}`,

    // drive
    DRIVE: `${prefix}/drive`,

    LOGIN: '/login',
    LOGOUT: '/logout',
    NOT_FOUND: '/not-found',
  });
};

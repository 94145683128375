import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES, SHOP_MODE_ROUTES } from 'app/constants';
import { FolderType, FolderTypeEnum } from 'app/models/Drive';
import { useShopStore } from 'app/stores';

interface BreadCrumbItem {
  title: string;
  href: string;
}

interface Props {
  folderType: string | null;
  path: string;
}

export const DriveBreadCrumb = ({ folderType, path }: Props) => {
  const { shop } = useShopStore();

  const breadCrumbItemList: BreadCrumbItem[] = path
    ? path.split('/')
      .reduce((prev: BreadCrumbItem[], curr) => [...prev,
        {
          title: decodeURIComponent(curr),
          href: `${
            prev.at(-1)?.href
              ? `${prev.at(-1)?.href}/`
              : `${shop ? SHOP_MODE_ROUTES(shop.shopId).DRIVE : ROUTES.DRIVE}?folderType=${folderType ?? 'image'}&path=`
          }${encodeURIComponent(curr)}`,
        },
      ], [])
    : [];

  return (
    <div className="flex flex-wrap gap-[8px] px-[10px]">
      <Link to={(shop ? SHOP_MODE_ROUTES(shop.shopId) : ROUTES).DRIVE} className="cursor-pointer">
        資料庫
      </Link>
      <div>/</div>
      <Link to={`${(shop ? SHOP_MODE_ROUTES(shop.shopId) : ROUTES).DRIVE}?folderType=${folderType ?? 'image'}`} className="cursor-pointer">
        {FolderTypeEnum[folderType as FolderType]}
      </Link>
      {breadCrumbItemList.map((item, index) => (
        <Fragment key={`${item.title}-${index}`}>
          <div>/</div>
          {index !== breadCrumbItemList.length - 1 ? (
            <Link
              to={item.href}
            >
              {item.title}
            </Link>
          ) : (
            <div>
              {item.title}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { APP_NAME } from 'app/configs';
import { AdminResponse } from 'app/models/Admin';

export type AuthStore = {
  profile: AdminResponse | null;
  setAdminProfile: (profile: AdminResponse | null) => void;
  removeAdminProfile: () => void;
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      profile: null,
      setAdminProfile: (profile) => set(() => ({
        profile,
      })),
      removeAdminProfile: () => set(() => ({
        profile: null,
      })),
    }),
    {
      name: `${APP_NAME}-auth-storage`,
    },
  ),
);

import { z } from 'zod';

import { PerformanceStatus, PerformanceStatusType } from 'app/models/Performance';

export const PERFORMANCE_FORM_SCHEMA = z
  .object({
    userId: z
      .number({
        required_error: '必選',
      })
      .min(1, { message: '必選' }),
    amount: z
      .string({
        required_error: '必填',
      })
      .refine((val) => parseInt(val, 10) > 0, { message: '請輸入正數' }),
    status: z
      .string({
        required_error: '必選',
      })
      .min(1, { message: '必選' })
      .refine((val) => val in PerformanceStatus)
      .transform((val) => val as PerformanceStatusType),
    createdAt: z
      .date()
      .optional(),
  });

export type PerformanceFormDataType = z.infer<typeof PERFORMANCE_FORM_SCHEMA>;

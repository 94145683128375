import { TimeStamp } from './Common';
import { DepartmentResponse } from './Department';
import { ShopResponse } from './Shop';

export interface AdminResponse extends TimeStamp {
  adminId: number;
  email: string | null;
  displayName: string | null;
  avatar: string | null;
  notificationToken: string | null;
  shopId?: number | null;
  departmentId?: number | null;
  role: AdminRoleType;
  allowImpersonate: boolean;
  AdminAuths: AdminAuth[];
  Department?: DepartmentResponse;
  Shop?: ShopResponse;
}

export enum AdminRole {
  超級管理員 = '超級管理員',
  管理員 = '管理員',
  店主 = '店主',
  店員 = '店員',
}

export type AdminRoleType = keyof typeof AdminRole;

export interface AdminAuth extends TimeStamp {
  adminAuthId: number
  adminId: number
  provider: string
  loginName: string | null
  email: string | null
  uid: string | null
  phoneNumber: string | null
}

export interface AdminLoginResponse {
  token: string;
  admin: AdminResponse;
}

export const ADMIN_LIST_SORT_OPTION_MAP = {
  displayName: '管理員名稱',
  'AdminAuths.loginName': '登入名稱',
  createdAt: '創建日期',
};

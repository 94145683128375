import { z } from 'zod';

import { FormPreviewItem } from 'app/models/Common';

export const DRIVE_FOLDER_FORM_SCHEMA = z
  .object({
    name: z
      .string()
      .min(1, { message: '必填' })
      .regex(/^((?!\/).)*$/, '錯誤的Folder名稱')
      .max(255, { message: '超出字數上限' }),
    image: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
      ),
  });

export type DriveFolderFormDataType = z.infer<typeof DRIVE_FOLDER_FORM_SCHEMA>;

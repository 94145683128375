import _ from 'lodash';
import
{
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FcFile, FcFolder, FcImageFile, FcVideoCall,
} from 'react-icons/fc';
import { IoIosArrowForward } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { searchFileInsideFolder } from 'app/apis/DriveServices';
import { isImageFile, isVideoFile } from 'app/helpers/Drive';
import { DriveFileResponse, FolderType, FolderTypeEnum } from 'app/models/Drive';
import cn from 'app/utils/ClassName';

import Button from '../Button';

interface Props {
  shopId?: string | number;
  currentFolderType: FolderType;
  className?: string;
  onConfirm?: (value: string) => void;
}

export const DriveSearchBar = ({
  shopId, currentFolderType, className, onConfirm,
}: Props) => {
  const [, setSearchParams] = useSearchParams();

  const searchBarRef = useRef<HTMLDivElement>(null);
  const searchFieldRef = useRef<HTMLInputElement>(null);

  const [displayContent, setDisplayContent] = useState<boolean>(false);

  const [, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResult, setSearchResult] = useState<DriveFileResponse[]>([]);

  const updateSearchString = _.debounce((value: string) => {
    setSearchText(value);
  }, 500);

  useEffect(() => {
    const getFolderSearch = async () => {
      if (searchText && searchText.length > 1) {
        try {
          setLoading(true);
          const resultRes = await searchFileInsideFolder({
            shopId,
            driveType: shopId ? 'shop' : 'public',
            folderType: currentFolderType,
            q: searchText,
          });
          setSearchResult(
            resultRes.map((res) => ({
              ...res,
            })),
          );
        } catch (err) {
          toast.error('請檢查你的網絡');
        } finally {
          setLoading(false);
        }
      }
    };
    getFolderSearch();
  }, [searchText, currentFolderType, shopId]);

  useEffect(() => {
    const listener = (event: Event) => {
      const el = searchBarRef?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      setDisplayContent(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);

  return (
    <div
      className={cn(
        'relative flex items-center gap-[16px] bg-white pl-[20px] border-b border-text-secondary has-[:focus]:border-brand-primary flex-1 max-w-[400px]',
        className,
      )}
      ref={searchBarRef}
    >
      <IoSearch />
      <input
        ref={searchFieldRef}
        onFocus={() => {
          setDisplayContent(true);
        }}
        className="peer min-h-[36px] flex-1 outline-0"
        onChange={(e) => {
          updateSearchString(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onConfirm?.(e.currentTarget.value);
            e.currentTarget.blur();
          }
        }}
      />
      {displayContent
        ? (
          <div className={cn(
            'absolute left-0 top-[56px] z-[15] h-fit max-h-[50vh] w-full overflow-y-auto rounded-[10px] bg-white shadow-card',
            !searchResult.length ? 'flex items-center justify-center size-full min-h-[300px]' : 'h-fit',
          )}
          >
            {searchResult.length
              ? searchResult.map(({
                type, fileName, folderType, dir,
              }) => {
                let icon = null;
                if (type === 'Folder') {
                  icon = <FcFolder />;
                } else if (isImageFile(fileName)) {
                  icon = <FcImageFile className="size-[18px]" />;
                } else if (isVideoFile(fileName)) {
                  icon = <FcVideoCall className="size-[18px]" />;
                } else {
                  icon = <FcFile className="size-[18px]" />;
                }

                return (
                  <Button
                    variant="text"
                    color="primary"
                    key={`${dir}/${fileName}`}
                    onClick={() => {
                      if (searchFieldRef.current) {
                        searchFieldRef.current.value = '';
                      }
                      setDisplayContent(false);
                      setSearchText('');
                      setSearchResult([]);
                      const params = new URLSearchParams();
                      params.set('folderType', folderType);
                      if (dir && dir !== '.') {
                        params.set('path', dir.split('/').map((item) => encodeURIComponent(item)).join('/'));
                      }
                      setSearchParams(params);
                    }}
                    containerStyle="w-full flex items-center text-left gap-[12px] p-[10px]"
                  >
                    {icon}
                    <div className="flex flex-1 flex-col">
                      <div className="text-text-primary">{fileName}</div>
                      <div className="text-text-light">
                        {FolderTypeEnum[folderType]}
                        {dir !== '.' ? `/${dir}` : null}
                      </div>

                    </div>
                    <IoIosArrowForward />
                  </Button>
                );
              })
              : (
                <div className="">
                  請輸入資料夾或檔案名稱
                </div>
              )}
          </div>
        )
        : null}
    </div>
  );
};

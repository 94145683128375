import { Dialog, Transition } from '@headlessui/react';
import {
  Fragment,
} from 'react';
import { IoClose } from 'react-icons/io5';
import 'swiper/css';
import 'swiper/css/navigation';
import ReactPlayer from 'react-player';

import { getDriveFileURL } from 'app/apis/DriveServices';
import { DriveFileResponse } from 'app/models/Drive';

interface Props {
  visible: boolean;
  data: DriveFileResponse | null;
  shopId?: number | string;
  onClose: () => void;
}

export const VideoModal = ({
  visible,
  data,
  shopId,
  onClose,
}: Props) => (
  <Transition appear show={visible} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/85" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-screen min-w-[100vw] text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="flex h-screen w-screen flex-col align-middle shadow-xl transition-all">
              <div className="flex w-full items-center gap-[16px] bg-bg-modal/80">
                <div className="line-clamp-1 flex-1 p-[16px] text-[16px] font-[600] text-white">
                  {data?.fileName}
                </div>
                <button
                  type="button"
                  className="absolute right-0 top-0 p-[20px]"
                  onClick={onClose}
                >
                  <IoClose className="size-[24px] text-white" />
                </button>
              </div>

              <div className="max-h-[90%] flex-1 p-[20px]">
                {data ? (
                  <ReactPlayer
                    url={getDriveFileURL({
                      folderType: data.folderType,
                      driveType: data.driveType,
                      shopId,
                      path: data.dir === '' ? data.fileName : `${data.dir}/${data.fileName}`,
                    })}
                    config={{ file: { forceHLS: false } }}
                    controls
                    width="100%"
                    height="100%"
                  />
                ) : null}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

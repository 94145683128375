import React, { lazy, Suspense } from 'react';

interface Opts {
  fallback: React.ReactNode;
}
type Unpromisify<T> = T extends Promise<infer P> ? P : never;

const refreshIfFail = async (componentImport: () => any) => {
  const hasRefreshed: boolean = !!JSON.parse(
    window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
  );
  // try to import the component
  try {
    const component = await componentImport();
    window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // reset after success
    return component;
  } catch (err) {
    if (!hasRefreshed) {
      // not been refreshed yet
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
      window.location.reload(); // refresh the page
      // eslint-disable-next-line consistent-return
      return;
    }
    // TODO: add error page if fail to load multiple time
    throw err;
  }
};

export const LazyLoad = <
  T extends Promise<any>,
  U extends React.ComponentType<any>,
>(
    importFunc: () => T,
    selectorFunc?: (s: Unpromisify<T>) => U,
    opts: Opts = { fallback: null },
  ) => {
  let lazyFactory: () => Promise<{ default: U }> = importFunc;

  if (selectorFunc) {
    lazyFactory = () => importFunc().then((module) => ({ default: selectorFunc(module) }));
  }

  const LazyComponent = lazy(() => refreshIfFail(lazyFactory));
  return (props: React.ComponentProps<U>): React.ReactNode => (
    <Suspense fallback={opts.fallback!}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

import { ReactNode } from 'react';
import { IoCloseSharp } from 'react-icons/io5';

import cn from 'app/utils/ClassName';

type BadgeColorType =
  | 'grey'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink';
interface BadgeProps {
  color?: BadgeColorType;
  children: ReactNode;
  variant?: 'default' | 'contain';
  rounded?: 'none' | 'sm' | 'md' | 'lg' | 'full';
  onClose?: () => void;
}

export const Badge = ({
  color = 'grey',
  children,
  variant = 'default',
  rounded = 'md',
  onClose,
}: BadgeProps) => (
  <span
    className={cn(
      'inline-flex items-center px-2 py-1 text-xs font-medium  ring-1 ring-inset min-w-max',
      // variant === 'default'
      { 'bg-gray-50 text-gray-400 ring-gray-500/10': color === 'grey' && variant === 'default' },
      { 'bg-red-50 text-red-400 ring-red-500/10': color === 'red' && variant === 'default' },
      { 'bg-orange-50 text-orange-400 ring-orange-500/10': color === 'orange' && variant === 'default' },
      { 'bg-yellow-50 text-yellow-400 ring-yellow-500/10': color === 'yellow' && variant === 'default' },
      { 'bg-green-50 text-green-500 ring-green-500/10': color === 'green' && variant === 'default' },
      { 'bg-blue-50 text-blue-400 ring-blue-500/10': color === 'blue' && variant === 'default' },
      { 'bg-indigo-50 text-indigo-400 ring-indigo-500/10': color === 'indigo' && variant === 'default' },
      { 'bg-purple-50 text-purple-400 ring-purple-500/10': color === 'purple' && variant === 'default' },
      { 'bg-pink-50 text-pink-400 ring-pink-500/10': color === 'pink' && variant === 'default' },
      // variant === 'contain'
      { 'bg-gray-300 text-gray-800 ring-gray-500/50': color === 'grey' && variant === 'contain' },
      { 'bg-red-300 text-red-800 ring-red-500/50': color === 'red' && variant === 'contain' },
      { 'bg-orange-300 text-orange-800 ring-orange-500/50': color === 'orange' && variant === 'contain' },
      { 'bg-yellow-300 text-yellow-800 ring-yellow-500/50': color === 'yellow' && variant === 'contain' },
      { 'bg-green-300 text-green-800 ring-green-500/50': color === 'green' && variant === 'contain' },
      { 'bg-blue-300 text-blue-800 ring-blue-500/50': color === 'blue' && variant === 'contain' },
      { 'bg-indigo-300 text-indigo-800 ring-indigo-500/50': color === 'indigo' && variant === 'contain' },
      { 'bg-purple-300 text-purple-800 ring-purple-500/50': color === 'purple' && variant === 'contain' },
      { 'bg-pink-300 text-pink-800 ring-pink-500/50': color === 'pink' && variant === 'contain' },
      // rounded
      { 'rounded-none': rounded === 'none' },
      { 'rounded-sm': rounded === 'sm' },
      { 'rounded-md': rounded === 'md' },
      { 'rounded-lg': rounded === 'lg' },
      { 'rounded-full': rounded === 'full' },
    )}
  >
    {children}
    {onClose && (
      <IoCloseSharp className="ml-2 size-4 shrink-0 cursor-pointer" onClick={onClose} />
    )}
  </span>
);

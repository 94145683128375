import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { APP_NAME } from 'app/configs';

export type LayoutStore = {
  isGlobalLoading: boolean;
  isSideBarOpen: boolean;
  isDrawerOpen: boolean;
  isOnDevMode: boolean;
  setIsGlobalLoading: (isGlobalLoading: boolean) => void;
  toggleSideBar: () => void;
  openDrawer: () => void;
  closeDrawer: () => void;
  toggleDevMode: () => void;
};

export const useLayoutStore = create<LayoutStore>()(
  persist(
    (set) => ({
      isGlobalLoading: false,
      isSideBarOpen: true,
      isDrawerOpen: false,
      isOnDevMode: false,
      setIsGlobalLoading: (isGlobalLoading) => set(() => ({
        isGlobalLoading,
      })),
      toggleSideBar: () => set((state) => ({
        isSideBarOpen: !state.isSideBarOpen,
      })),
      openDrawer: () => set(() => ({
        isDrawerOpen: true,
      })),
      closeDrawer: () => set(() => ({
        isDrawerOpen: false,
      })),
      toggleDevMode: () => set((state) => ({
        isOnDevMode: !state.isOnDevMode,
      })),
    }),
    {
      name: `${APP_NAME}-layout-storage`,
    },
  ),
);

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { APP_NAME } from 'app/configs';

export type DriveStore = {
  isListView: boolean
  toggleIsListView: () => void
};

export const useDriveStore = create<DriveStore>()(
  persist(
    (set) => ({
      isListView: false,
      toggleIsListView: () => set(({ isListView }) => ({
        isListView: !isListView,
      })),
    }),
    {
      name: `${APP_NAME}-drive-storage`,
    },
  ),
);

import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';

import { UploadFileItem } from 'app/models/Drive';
import cn from 'app/utils/ClassName';

import Button from '../Button';
import { Spinner } from '../Spinner';

interface Props {
  files: UploadFileItem[];
  onClose: () => void;
}

export const UploadFilePopUp = ({ files, onClose }: Props) => {
  const [minimize, setMinimize] = useState<boolean>(false);

  useEffect(() => {
    if (!files.length) {
      setMinimize(false);
    }
  }, [files]);

  if (!files.length) {
    return null;
  }

  return (
    <div className="absolute bottom-[20px] right-[20px] w-[400px] overflow-hidden rounded-[5px]">
      <span
        className="flex items-center justify-between bg-[#74788d] p-[10px]"
        onClick={() => {
          setMinimize((prev) => !prev);
        }}
        role="presentation"
      >
        <div className="text-white">
          上載
          {files.length}
          個檔案
        </div>
        <Button
          variant="text"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            if (files.every((file) => file.status !== 'uploading')) {
              onClose();
            } else {
              setMinimize(true);
            }
          }}
        >
          <IoClose className="text-white" />
        </Button>
      </span>

      <div className={cn('flex flex-col gap-[8px] overflow-y-auto bg-white transition-[height] duration-500 ease-in-out', minimize ? 'h-0' : 'h-[300px]')}>
        {files.map((file) => (
          <div className="flex items-center gap-[12px] p-[10px]">
            <div className="flex-1 whitespace-pre-line break-all">{file.name}</div>
            <div>
              {file.status === 'success' && (
                <div className="flex size-[18px] shrink-0 items-center justify-center rounded-full bg-green-400">
                  <FaCheck className="size-[12px] text-white" />
                </div>
              )}
              {file.status === 'uploading' && (
                <Spinner size="sm" />
              )}
              {file.status === 'fail' && (
                <div className="size-[18px] shrink-0 rounded-full bg-brand-attention">
                  <IoClose className="text-white" />
                </div>
              )}

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import { AiOutlineStop } from 'react-icons/ai';
import { CgFileDocument } from 'react-icons/cg';
import { CiPhone } from 'react-icons/ci';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { LuBadgeInfo } from 'react-icons/lu';
import { MdOutlineEmail } from 'react-icons/md';
import { PiCurrencyCircleDollarDuotone } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { activateOneUser, deactivateOneUser } from 'app/apis/UserServices';
import Button from 'app/components/Button';
import { ROUTES } from 'app/constants';
import {
  UserResponse,
} from 'app/models/User';

import { Badge } from '../Badge';
import Modal from '../Modal';

import { InfoDisplay } from './InfoDisplay';

import ('dayjs/locale/zh-hk');

dayjs.extend(relativeTime);

interface Props {
  user: UserResponse | null;
  updateUser: () => void;
}

export const UserInfoTabItem = ({
  user,
  updateUser,
}: Props) => {
  const [activateLoading, setActivateLoading] = useState<boolean>(false);
  const [activateStatusModalVisible, setActivateStatusModalVisible] = useState<boolean>(false);

  const changeActivateStatus = async () => {
    if (user) {
      try {
        setActivateLoading(true);
        if (user.status === '已停用') {
          await activateOneUser(user.userId);
          toast.success('啟用會員成功');
        } else {
          await deactivateOneUser(user.userId);
          toast.success('停用會員成功');
        }
        updateUser();
        setActivateStatusModalVisible(false);
      } catch {
        toast.error(`${user.status === '已停用' ? '啟用' : '停用'}會員失敗`);
      } finally {
        setActivateLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col gap-[12px] overflow-y-scroll p-[24px]">
      <div className="border-b">
        <h3 className="text-text-primary">狀態</h3>

        <div className="py-[16px]">
          <div className="flex w-2/3 flex-row gap-[20px]">
            {user?.userId ? (
              <InfoDisplay
                icon={
                  <CgFileDocument className="size-5 text-text-primary" />
              }
                label="更改資料"
                value={(
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="text-[16px]"
                    href={ROUTES.USERS_EDIT(user.userId)}
                  >
                    更改
                  </Button>
              )}
              />
            ) : null}

            {user?.status && (
            <InfoDisplay
              icon={
                  user?.status === '已啟用' ? (
                    <AiOutlineStop className="size-5 text-brand-attention" />
                  ) : (
                    <IoCheckmarkCircleOutline className="size-5 text-text-primary" />
                  )
                }
              label={user?.status === '已啟用' ? '停用會員' : '啟用會員'}
              value={
                  user?.status === '已啟用' ? (
                    <Button
                      variant="text"
                      color="primary"
                      containerStyle="text-[16px]"
                      onClick={() => setActivateStatusModalVisible(true)}
                    >
                      停用
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      color="primary"
                      containerStyle="text-[16px]"
                      onClick={() => setActivateStatusModalVisible(true)}
                    >
                      啟用
                    </Button>
                  )
                }
            />
            )}
          </div>
        </div>
      </div>

      <div className="border-b">
        <h3 className="text-text-primary">聯絡方法</h3>

        <div className="py-[16px]">
          <div className="flex gap-[20px]">
            <InfoDisplay
              icon={<CiPhone className="size-5 stroke-1 text-brand-primary" />}
              label="聯絡電話"
              value={user?.contactPhone ? `${user?.countryCallingCode} ${user?.contactPhone}` : '-'}
            />
            <InfoDisplay
              icon={<MdOutlineEmail className="size-5 text-brand-primary" />}
              label="電郵地址"
              value={user?.email ?? '-'}
            />
            <InfoDisplay
              icon={<MdOutlineEmail className="size-5 text-brand-primary" />}
              label="通訊電郵地址"
              value={user?.contactEmail ?? '-'}
            />
          </div>
        </div>
      </div>

      <div className="py-[16px]">
        <div className="flex w-1/3 gap-[20px]">
          <InfoDisplay
            icon={<PiCurrencyCircleDollarDuotone className="size-5 text-text-secondary" />}
            label="繳款申請"
            value={(
              <Button
                variant="text"
                color="primary"
                href={ROUTES.TRANSACTION_LIST({
                  userId: user?.userId,
                })}
              >
                查看繳款申請
              </Button>
              )}
          />
          <InfoDisplay
            icon={<PiCurrencyCircleDollarDuotone className="size-5 text-text-secondary" />}
            label="龍虎榜"
            value={(
              <Button
                variant="text"
                color="primary"
                href={ROUTES.PERFORMANCE_LIST({
                  userId: user?.userId.toString(),
                })}
              >
                查看龍虎榜
              </Button>
              )}
          />
          <InfoDisplay
            icon={<PiCurrencyCircleDollarDuotone className="size-5 text-text-secondary" />}
            label="會員帳單"
            value={(
              <Button
                variant="text"
                color="primary"
                href={ROUTES.STATEMENTS_LIST({
                  userId: user?.userId.toString(),
                })}
              >
                查看會員帳單
              </Button>
              )}
          />
        </div>
      </div>

      <div className="border-b">
        <h3 className="text-text-primary">資料</h3>

        <div className="py-[16px]">
          <div className="grid grid-cols-2 gap-[16px]">
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="會員名稱"
              value={user?.displayName || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="卡片名稱"
              value={user?.chineseName || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="卡片地址"
              value={user?.cardAddress || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="職位"
              value={user?.jobTitle || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="營業員編號"
              value={user?.agentLicenseNumber || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="會員編號"
              value={user?.displayedMemberId || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="會員等級"
              value={user?.memberLevel || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="Family"
              value={user?.family || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="區域名稱"
              value={user?.branchName || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="區域編號"
              value={user?.branchCode || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="入行日期"
              value={user?.entryDate
                ? `${dayjs(user.entryDate).format('YYYY-MM-DD')} [${dayjs(user.entryDate).locale('zh-hk').fromNow()}]`
                : '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="出生日期"
              value={
                user?.birthDate ? dayjs(user.birthDate).format('YYYY-MM-DD') : '-'
              }
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="座右銘"
              value={user?.quote || '-'}
            />
            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="會員Tag權限"
              value={user?.UserTags?.length
                ? (
                  <div className="flex flex-wrap gap-[4px]">
                    {user.UserTags.map((tag) => (
                      <Badge
                        color="blue"
                        key={tag.tagName}
                      >
                        {tag.tagName}
                      </Badge>
                    ))}
                  </div>
                )
                : '-'}
            />

            <InfoDisplay
              icon={<LuBadgeInfo className="size-5 text-text-secondary" />}
              label="首次登入時間"
              value={
                user?.firstLoginTime
                  ? dayjs(user?.firstLoginTime).format('YYYY-MM-DD HH:mm')
                  : '從未登入'
              }
            />
          </div>
        </div>
      </div>
      <Modal
        title={`確認${user?.status === '已停用' ? '啟用' : '停用'}會員？`}
        loading={activateLoading}
        visible={activateStatusModalVisible}
        onClose={() => { setActivateStatusModalVisible(false); }}
        submitOnClick={() => { changeActivateStatus(); }}
      />
    </div>
  );
};

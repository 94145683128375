import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { APP_NAME } from 'app/configs';
import { ShopResponse } from 'app/models/Shop';

export type ShopStore = {
  shop: ShopResponse | null;
  selectOneShop: (shop: ShopResponse) => void;
  resetShop: () => void;
};

export const useShopStore = create<ShopStore>()(
  persist(
    (set) => ({
      shop: null,
      selectOneShop: (shop) => set(() => ({
        shop,
      })),
      resetShop: () => set(() => ({
        shop: null,
      })),
    }),
    {
      name: `${APP_NAME}-shop-storage`,
    },
  ),
);

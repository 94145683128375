import { z } from 'zod';

import { FormPreviewItem } from 'app/models/Common';
import { QuestionStyle, SurveyQuestionSelectType } from 'app/models/Survey';

export const SURVEY_BASIC_INFO_FORM_SCHEMA = z
  .object({
    surveyId: z
      .number()
      .optional(),
    title: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填')
      .max(200, '超出字數上限'),
    shopId: z
      .number()
      .nullable(),
    images: z
      .array(z
        .custom<FormPreviewItem>()
        .or(z.instanceof(File))),
    description: z
      .string(),
    successTitle: z
      .string(),
    successSubtitle: z
      .string(),
    maxSubmissionPerPerson: z
      .string(),
    mailingList: z
      .array(
        z
          .string()
          .email(),
      ).superRefine((items, ctx) => {
        const seen = new Set<string>();
        Object.values(items).forEach((item, i) => {
          if (seen.has(item)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: '重複',
              path: [i],
            });
          } else {
            seen.add(item);
          }
        });
      }),
    successContent: z
      .string(),
  })
  .refine(
    ({ shopId }) => shopId,
    { message: '必選', path: ['shopId'] },
  );

type SurveyBasicInfoFormDataType = z.infer<typeof SURVEY_BASIC_INFO_FORM_SCHEMA>;

export const INITIAL_SURVEY_BASIC_INFO: SurveyBasicInfoFormDataType = {
  title: '',
  shopId: null,
  images: [],
  description: '',
  successTitle: '',
  successSubtitle: '',
  maxSubmissionPerPerson: '',
  mailingList: [],
  successContent: '',
};

export const SURVEY_QUESTIONS_FORM_SCHEMA = z
  .array(
    z.object({
      questionId: z
        .number()
        .optional(),
      title: z
        .string({
          required_error: '必填',
        })
        .min(1, '必填')
        .max(100, '超出字數上限'),
      type: z
        .custom<SurveyQuestionSelectType>(),
      style: z
        .custom<QuestionStyle>()
        .default('Boxes'),
      QuestionOptions: z
        .array(
          z.object({
            questionOptionId: z
              .number()
              .optional(),
            questionId: z
              .number()
              .optional(),
            title: z
              .string({
                required_error: '必填',
              }).min(1, '必填'),
            needAdditionalInput: z
              .boolean(),
            additionalInputLabel: z
              .string()
              .max(50, '超出字數上限'),
          })
            .refine(
              ({ needAdditionalInput, additionalInputLabel }) => !needAdditionalInput || !!additionalInputLabel,
              {
                message: '必填',
                path: ['additionalInputLabel'],
              },
            ),
        ),
      minAttachment: z
        .string(),
      maxAttachment: z
        .string(),
      firstOptionRemarks: z
        .string()
        .max(10, '超出字數上限'),
      lastOptionRemarks: z
        .string()
        .max(10, '超出字數上限'),
      isRequired: z
        .boolean(),
      isMultiple: z
        .boolean(),
      content: z
        .string(),
    })
      .refine(({ minAttachment, maxAttachment }) => Number(maxAttachment) >= Number(minAttachment), {
        message: '最多數量不能少於最少數量',
        path: ['maxAttachment'],
      }),
  );

type SurveyQuestionFormDataType = z.infer<typeof SURVEY_QUESTIONS_FORM_SCHEMA>;

export const INITIAL_SURVEY_QUESTION: SurveyQuestionFormDataType[number] = {
  questionId: undefined,
  title: '',
  type: '輸入題',
  style: 'Boxes',
  QuestionOptions: [],
  firstOptionRemarks: '',
  lastOptionRemarks: '',
  isRequired: true,
  isMultiple: false,
  content: '',
  minAttachment: '',
  maxAttachment: '',
};

export const SURVEY_FORM_SCHEMA = z
  .object({
    basicInfo: SURVEY_BASIC_INFO_FORM_SCHEMA,
    Questions: SURVEY_QUESTIONS_FORM_SCHEMA,
  });

export type SurveyFormDataType = z.infer<typeof SURVEY_FORM_SCHEMA>;

import { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  label: string;
  value: ReactNode;
}

export const InfoDisplay = ({ icon, label, value }: Props) => (
  <div className="col-span-1 flex flex-row items-center">
    <div className="pr-[16px]">
      {icon}
    </div>
    <div className="flex w-full flex-col">
      <label className="h-[18px] text-[12px] text-text-secondary">{label}</label>
      <div className="flex h-[27px] items-center truncate text-[18px] text-text-primary">{value}</div>
    </div>
  </div>
);

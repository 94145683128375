import { AdminLoginResponse, AdminResponse, AdminRoleType } from 'app/models/Admin';
import { Filter, ListResult } from 'app/models/Common';

import Axios from '.';

export interface AdminLoginParams {
  loginName: string;
  password: string;
  rememberMe?: boolean;
}

export const adminLogin = async (params: AdminLoginParams) => {
  const { data } = await Axios.post<AdminLoginResponse>('/admins/passwordAuth/SignIn', params);
  return data;
};

export const adminLogout = async () => {
  const { data } = await Axios.post('/admins/me/logout');
  return data;
};

export interface GetAdminListParams extends Filter<AdminResponse> {
  displayName?: string;
}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<AdminResponse>>('/admins', { params });
  return data;
};

export const getOneAdmin = async (id: string | number) => {
  const { data } = await Axios.get<AdminResponse>(`/admins/${id}`);
  return data;
};

export interface CreateOneAdminParams {
  email?: string
  displayName: string
  shopId?: number | null;
  departmentId?: number | null;
  role: AdminRoleType
  loginName: string
  password: string
}

export const createOneAdmin = async (params: CreateOneAdminParams) => {
  const { data } = await Axios.post<AdminResponse>('/admins', params);
  return data;
};

export const deleteOneAdmin = async (id: string | number) => {
  const { data } = await Axios.delete(`/admins/${id}`);
  return data;
};

export interface UpdateOneAdminParams {
  displayName?: string;
  role?: AdminRoleType;
  shopId?: number | null;
  departmentId?: number | null;
}
export const updateOneAdmin = async (id: string | number, params: UpdateOneAdminParams) => {
  const { data } = await Axios.patch(`/admins/${id}`, params);
  return data;
};

export interface UpdateOneAdminPasswordParams {
  newPassword: string;
}

export const updateOneAdminPassword = async (adminId: string | number, params: UpdateOneAdminPasswordParams) => {
  const { data } = await Axios.post(`/admins/${adminId}/resetPassword`, params);
  return data;
};

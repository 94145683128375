import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';

import cn from 'app/utils/ClassName';

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> {
  name: string;
  type?: HTMLInputTypeAttribute | 'textarea';
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  isDisable?: boolean;
  showPassword?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  onChangeCallback?: (val: string) => void
}

export const InputField = ({
  name,
  type = 'text',
  label,
  placeholder,
  defaultValue,
  isDisable = false,
  showPassword = false,
  containerClassName,
  inputClassName,
  labelClassName,
  onChangeCallback,
  ...rest
}: Props) => {
  const { field, fieldState } = useController({
    name,
    defaultValue,
  });

  const rootClassName = cn(
    'relative h-[46px] w-full px-[16px] focus:outline-none focus:ring-0 ',
    'rounded-[6px] border-[1.24px] bg-white py-[12px] text-[16px] leading-[19.36px] tracking-[0.01em] text-text-primary placeholder:text-text-light',
    isDisable ? 'cursor-not-allowed !bg-bg-disabled' : '',
    inputClassName,
  );
  return (
    <div
      className={cn('relative flex flex-col gap-y-[8px]', containerClassName)}
      // style={type !== 'textarea' ? { height: 94 } : {}}
    >
      <div className="flex flex-wrap items-end justify-between">
        {label ? (
          <label
            htmlFor={name}
            className={cn(
              'relative block text-[14px] font-semibold leading-[16.94px] text-text-primary',
              labelClassName,
            )}
          >
            {label}
          </label>
        ) : (
          <div />
        )}
      </div>
      {type === 'textarea' ? (
        <textarea
          {...field}
          id={name}
          name={name}
          placeholder={placeholder || label}
          disabled={isDisable}
          className={rootClassName}
          autoComplete="off"
          spellCheck="false"
          onChange={(e) => {
            field.onChange(e.currentTarget.value);
            onChangeCallback?.(e.currentTarget.value);
          }}
          aria-invalid={fieldState.error ? 'true' : 'false'}
        />
      ) : (
        <div className="relative">
          <input
            {...field}
            id={name}
            name={name}
            type={showPassword ? 'text' : type}
            placeholder={placeholder || label}
            className={rootClassName}
            autoComplete="off"
            spellCheck="false"
            aria-invalid={fieldState.error ? 'true' : 'false'}
            disabled={isDisable}
            onChange={(e) => {
              field.onChange(e.currentTarget.value);
              onChangeCallback?.(e.currentTarget.value);
            }}
            checked={type === 'checkbox' ? field.value : undefined}
            onWheel={type === 'number' ? (e) => { e.currentTarget.blur(); } : undefined}
            onKeyDown={type === 'number' ? (e) => {
              if (['e', 'E', '+'].includes(e.key)) { e.preventDefault(); }
            } : undefined}
            {...rest}
          />
        </div>
      )}

      {fieldState.error ? (
        <p className="text-left text-[12px] font-normal leading-[14.32px] tracking-[0.01em] text-brand-attention">
          {fieldState.error.message}
        </p>
      ) : <div className="h-[14.32px]" />}
    </div>
  );
};

export default InputField;

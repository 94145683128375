import { Outlet } from 'react-router-dom';

import { ROUTES } from 'app/constants';
import { MainLayout } from 'app/containers';
import { CourseFormPage, CourseListPage } from 'app/pages/CoursePage';
import { DrivePage } from 'app/pages/DrivePage';
import { EventFormPage, EventListPage } from 'app/pages/EventPage';
import { HomePage } from 'app/pages/HomePage';
import { LoginPage } from 'app/pages/LoginPage';
import { LogoutPage } from 'app/pages/LogoutPage/LazyLoad';
import { NewsFormPage, NewsListPage } from 'app/pages/NewsPage';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { ProductFormPage, ProductListPage } from 'app/pages/ProductPage';
import {
  ShopFormPage, ShopListPage,
} from 'app/pages/ShopPage';

const App = () => (
  <MainLayout>
    <Outlet />
  </MainLayout>
);

export const ShopProtectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: ROUTES.HOME,
        element: <HomePage />,
      },
      // shop
      {
        path: `/shop/:shopId${ROUTES.SHOP_LIST}`,
        element: <ShopListPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.SHOP_LIST}/:type`,
        element: <ShopFormPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.SHOP_LIST}/:type/:id`,
        element: <ShopFormPage />,
      },
      // event
      {
        path: `/shop/:shopId${ROUTES.EVENTS_LIST}`,
        element: <EventListPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.EVENTS_LIST}/:type`,
        element: <EventFormPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.EVENTS_LIST}/:type/:id`,
        element: <EventFormPage />,
      },
      // news
      {
        path: `/shop/:shopId${ROUTES.NEWS_LIST}`,
        element: <NewsListPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.NEWS_LIST}/:type`,
        element: <NewsFormPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.NEWS_LIST}/:type/:id`,
        element: <NewsFormPage />,
      },
      // product
      {
        path: `/shop/:shopId${ROUTES.PRODUCT_LIST}`,
        element: <ProductListPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.PRODUCT_LIST}/:type`,
        element: <ProductFormPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.PRODUCT_LIST}/:type/:id`,
        element: <ProductFormPage />,
      },
      // course
      {
        path: `/shop/:shopId${ROUTES.COURSE_LIST}`,
        element: <CourseListPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.COURSE_LIST}/:type`,
        element: <CourseFormPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.COURSE_LIST}/:type/:id`,
        element: <CourseFormPage />,
      },
      {
        path: `/shop/:shopId${ROUTES.DRIVE}`,
        element: <DrivePage />,
      },
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
      { path: '/logout', element: <LogoutPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
];

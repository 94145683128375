import { z } from 'zod';

import { PHONE_REGEX } from './Common';

export const INFO_FORM_SCHEMA = z
  .object({
    title: z
      .string()
      .min(1, { message: '必填' })
      .max(200, { message: '超出字數上限' }),
    url: z
      .string()
      .min(1, { message: '必填' })
      .max(500, { message: '超出字數上限' })
      .regex(/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g, { message: '必須是有效的URL' }),
    address: z
      .string()
      .regex(/^[0-9A-Za-z\u4e00-\u9fa5]/, { message: '地址必須以數字、字母或中文字符開頭' })
      .min(1, { message: '必填' })
      .max(500, '超出字數上限'),
    phones: z
      .array(z
        .object({
          phone: z.string()
            .regex(PHONE_REGEX, { message: '必須是有效的電話號碼' })
            .min(1, { message: '必填' })
            .max(255, { message: '超出字數上限' }),
        }))
      .superRefine((items, ctx) => {
        const seen = new Set<string>();
        Object.values(items).forEach((item, i) => {
          if (seen.has(item.phone)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: '重複的電話號碼',
              path: [i, 'phone'],
            });
          } else {
            seen.add(item.phone);
          }
        });
      }),
    fax: z
      .union([
        z.string()
          .max(100, { message: '超出字數上限' })
          .regex(PHONE_REGEX, { message: '必須是有效的傳真號碼' }),
        z.literal(''),
      ]),
    email: z
      .string()
      .email({ message: '無效的電子郵件地址' })
      .includes('@', { message: '無效的電子郵件地址' })
      .min(1, { message: '必填' })
      .max(200, { message: '超出字數上限' }),
  });

export type InfoFormDataType = z.infer<typeof INFO_FORM_SCHEMA>;

import { AiTwotoneShop } from 'react-icons/ai';
import {
  PiCalendarDuotone,
  PiNewspaperDuotone,
  PiDropboxLogoDuotone,
  PiBooksDuotone,
  PiFolderOpenDuotone,
} from 'react-icons/pi';

import { SHOP_MODE_ROUTES } from '.';

// remark:
// the key in children is not important, it is just the unique key for mapping.
// while the key in parent is for the active class in NavLink.
// the href in children must start with '/parent-key'.

export const SHOP_MENUS = (shopId: number | string) => [
  {
    key: 'events',
    title: '活動',
    icon: <PiCalendarDuotone className="size-5" />,
    href: SHOP_MODE_ROUTES(shopId).EVENTS_LIST,
  },
  {
    key: 'news',
    title: '資訊',
    icon: <PiNewspaperDuotone className="size-5" />,
    href: SHOP_MODE_ROUTES(shopId).NEWS_LIST,
  },
  {
    key: 'product',
    title: '貨品',
    icon: <PiDropboxLogoDuotone className="size-5" />,
    href: SHOP_MODE_ROUTES(shopId).PRODUCT_LIST,
  },
  {
    key: 'course',
    title: '課程',
    icon: <PiBooksDuotone className="size-5" />,
    href: SHOP_MODE_ROUTES(shopId).COURSE_LIST,
  },
  {
    key: 'drive',
    title: '資料庫',
    icon: <PiFolderOpenDuotone className="size-5" />,
    href: SHOP_MODE_ROUTES(shopId).DRIVE,
  },
  {
    key: 'shop',
    title: '商店',
    icon: <AiTwotoneShop className="size-5" />,
    href: SHOP_MODE_ROUTES(shopId).SHOP_LIST,
  },
];

import {
  ReactNode, Suspense,
} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AxiosInterceptor } from 'app/apis';
import { APP_NAME, TOAST_TIME } from 'app/configs';

import 'react-toastify/dist/ReactToastify.css';

interface Props {
  children: ReactNode;
}

export const Providers = ({ children }: Props) => (
  <Suspense>
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <title>{APP_NAME}</title>
          <meta name="description" content="Admin Panel" />
        </Helmet>
        <AxiosInterceptor>{children}</AxiosInterceptor>
        <ToastContainer
          position="top-right"
          autoClose={TOAST_TIME}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </HelmetProvider>
  </Suspense>
);

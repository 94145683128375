import { useRoutes } from 'react-router-dom';

import { useAuthStore, useShopStore } from 'app/stores';

import { ProtectedRoutes } from './ProtectedRoutes';
import { PublicRoutes } from './PublicRoutes';
import { ShopProtectedRoutes } from './ShopProtectedRoutes';

export const Routes = () => {
  const { profile } = useAuthStore();
  const { shop } = useShopStore();

  let routes = PublicRoutes;

  if (shop) {
    routes = ShopProtectedRoutes;
  } else if (profile) {
    routes = ProtectedRoutes;
  } else {
    routes = PublicRoutes;
  }

  const element = useRoutes([...routes]);

  return element;
};

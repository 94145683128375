import { z } from 'zod';

import { AdminRoleType } from 'app/models/Admin';

export const ADMIN_FORM_SCHEMA = z
  .object({
    type: z.enum(['new', 'changeDisplayName', 'changePassword']),
    displayName: z
      .string()
      .max(50, { message: '不可多於50個字元' }),
    role: z
      .custom<AdminRoleType>()
      .nullable(),
    shopId: z
      .number()
      .nullable(),
    departmentId: z
      .number()
      .nullable(),
    loginName: z
      .string()
      .max(50, { message: '不可多於50個字元' }),
    password: z
      .string()
      .max(20, { message: '不可多於20個字元' }),
    confirmPassword: z
      .string()
      .max(20, { message: '不可多於20個字元' }),
  })
  .refine(
    ({ role }) => role,
    { message: '必填', path: ['role'] },
  )
  .refine(
    ({ role, departmentId }) => role !== '管理員' || departmentId,
    { message: '必選', path: ['departmentId'] },
  )
  .refine(
    ({ role, shopId }) => role === '超級管理員' || role === '管理員' || shopId,
    { message: '必選', path: ['shopId'] },
  )
  .refine(
    ({ type, displayName }) => type === 'changePassword' || displayName,
    { message: '必填', path: ['displayName'] },
  )
  .refine(
    ({ type, loginName }) => type !== 'new' || loginName,
    { message: '必填', path: ['loginName'] },
  )
  .refine(
    ({ type, password }) => type === 'changeDisplayName' || password,
    { message: '必填', path: ['password'] },
  )
  .refine(
    ({ type, confirmPassword }) => type === 'changeDisplayName' || confirmPassword,
    { message: '必填', path: ['confirmPassword'] },
  )
  .refine(
    ({ type, displayName }) => type === 'changePassword' || displayName.length >= 2,
    { message: '最少要2個字元', path: ['displayName'] },
  )
  .refine(
    ({ type, loginName }) => type !== 'new' || loginName.length >= 2,
    { message: '最少要2個字元', path: ['loginName'] },
  )
  .refine(
    ({ type, password }) => type === 'changeDisplayName' || password.length >= 8,
    { message: '最少要8個字元', path: ['password'] },
  )
  .refine(
    ({ type, confirmPassword }) => type === 'changeDisplayName' || confirmPassword.length >= 8,
    { message: '最少要8個字元', path: ['confirmPassword'] },
  )
  .refine(
    ({ password, confirmPassword }) => password === confirmPassword,
    { message: '密碼不相同', path: ['confirmPassword'] },
  );
export type AdminFormDataType = z.infer<typeof ADMIN_FORM_SCHEMA>;

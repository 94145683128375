import 'flatpickr/dist/themes/material_blue.css';
import 'app/assets/scss/datePicker.scss';

import clsx from 'clsx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import locale from 'flatpickr/dist/l10n/zh-tw';
import Flatpickr from 'react-flatpickr';
import { useController } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';

dayjs.extend(utc);

interface Props {
  name: string;
  label?: string;
  mode?: 'single' | 'range';
  placeholder?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  enableTime?: boolean;
  defaultValue?: Date;
  disabled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  showMonths?: number;
  afterOnChange?: (dates?: Date[]) => void;
  isClearable?: boolean;
}

export const DateField = ({
  name,
  label,
  mode = 'single',
  placeholder,
  minDate,
  maxDate,
  enableTime = false,
  defaultValue,
  disabled = false,
  containerClassName,
  labelClassName,
  showMonths = 1,
  isClearable = false,
  afterOnChange,
}: Props) => {
  const { field, fieldState } = useController({
    name,
    defaultValue,
  });

  const { value, onChange } = field;

  return (
    <div className={clsx('relative space-y-[8px]', containerClassName)} id={name}>
      <div className="flex flex-wrap items-end justify-between">
        {label ? (
          <label
            htmlFor={name}
            className={clsx(
              'relative block text-[14px] font-semibold leading-[16.94px] text-text-primary',
              labelClassName,
            )}
          >
            {label}
          </label>
        ) : (
          <div />
        )}
      </div>
      <div className="relative">
        <Flatpickr
          className={clsx(
            'block h-[46px] w-full bg-white px-[16px] focus:outline-none focus:ring-0',
            'rounded-[6px] border-[1.24px] border-border-brand py-[12px] text-[16px] leading-[19.36px] focus:border-border-brand',
            'tracking-[0.01em] text-text-primary placeholder:text-text-light',
            disabled ? 'cursor-not-allowed !bg-bg-disabled' : '',
          )}
          key={`${disabled}`}
          placeholder={placeholder || '日期'}
          disabled={disabled}
          options={{
            mode,
            altInput: true,
            minDate,
            maxDate,
            altFormat: enableTime ? 'Y-m-d H:i' : 'Y-m-d',
            dateFormat: enableTime ? 'Y-m-d H:i' : 'Y-m-d',
            enableTime,
            locale: locale.zh_tw,
            showMonths,
            disableMobile: true,
          }}
          lang="zh"
          value={value}
          onChange={(date) => {
            if (mode === 'range') {
              if (date && date.length === 2) {
                onChange([dayjs(date[0]).toDate(), dayjs(date[1]).toDate()]);
              } else {
                onChange([dayjs(date[0]).toDate(), null]);
              }
            } else if (date[0]) {
              onChange(dayjs(date[0]).toDate());
            } else {
              onChange(null);
            }
            afterOnChange?.(date);
          }}
        />
        {isClearable && !!value && (
          <div className="absolute inset-y-0 right-0 flex items-center justify-center">
            <button
              type="button"
              onClick={() => {
                onChange(null);
                afterOnChange?.();
              }}
              className="p-[10px]"
            >
              <IoClose className="size-5 cursor-pointer text-text-light" />
            </button>
          </div>
        )}
      </div>

      {fieldState.error ? (
        <p className="text-left text-[12px] font-normal leading-[16px] tracking-[0.01em] text-brand-attention">
          {fieldState.error.message}
        </p>
      ) : (
        <div className="h-[16px]" />
      )}
    </div>
  );
};

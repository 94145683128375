import React from 'react';
import { useImage } from 'react-image';

import EmptyImage from 'app/assets/svg/empty_image.svg';
import cn from 'app/utils/ClassName';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  className?: string;
  fallbackImageList?: string[];
}

export const Image = ({
  src,
  className,
  fallbackImageList = [],
  alt,
  ...props
}: Props) => {
  const { src: source, isLoading } = useImage({
    srcList: [
      src,
      ...fallbackImageList,
      EmptyImage, // fallback image
    ],
    useSuspense: false,
  });

  if (isLoading) {
    return <div className={cn(className, 'animate-pulseImage')}><div className="size-full bg-text-light/40" /></div>;
  }

  return <img src={source} {...props} alt={alt} className={cn(className, source === EmptyImage && 'object-contain')} draggable={false} />;
};

import dayjs from 'dayjs';
import { z } from 'zod';

import { validatePermission } from 'app/helpers/CommerceItem';
import { PaymentOptionType, ReviewStatusType, SurveyOption } from 'app/models/CommerceItem';
import { FormPreviewItem, PublishStatusType } from 'app/models/Common';
import { MemberSurveyOption } from 'app/models/Course';
import { DriveType, FolderType } from 'app/models/Drive';

import { COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA, COMMERCE_ITEM_PERMISSION_FORM_SCHEMA } from './CommerceItem';
import { PHONE_REGEX } from './Common';

export const COURSE_BASIC_INFO_FORM_SCHEMA = z
  .object({
    title: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填')
      .max(200, '超出字數上限'),
    shopId: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    subtitle: z
      .string()
      .max(200, '超出字數上限'),
    commerceItemCategoryId: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    CommerceItemHashTags: z
      .array(
        z.object({
          commerceItemHashTagId: z
            .number().optional(),
          hashTag: z
            .string().min(1, '必填'),
        }),
      ),
    coverPhoto: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      )
      .min(1, '必填'),
    images: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      ),
    description: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填'),
    attachment: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
    publishStatus: z
      .custom<PublishStatusType>(),
    publishedDate: z
      .date()
      .nullable(),
    publishEndDate: z
      .date()
      .nullable(),
    caution: z
      .string(),
  })
  .refine(
    ({ publishStatus, publishedDate }) => publishStatus === 'Published' || publishedDate,
    { message: '請選擇開始發佈時間', path: ['publishedDate'] },
  )
  .refine(
    ({ publishStatus, publishedDate, publishEndDate }) => !publishEndDate
    || dayjs(publishStatus === 'Unpublished' ? publishedDate || undefined : undefined).isSameOrBefore(dayjs(publishEndDate)),
    { message: '結束發佈時間不能比開始發佈時間早', path: ['publishEndDate'] },
  );

export type CourseBasicInfoFormDataType = z.infer<typeof COURSE_BASIC_INFO_FORM_SCHEMA>;

export const INITIALIZED_COURSE_BASIC_INFO: CourseBasicInfoFormDataType = {
  title: '',
  subtitle: '',
  CommerceItemHashTags: [],
  coverPhoto: [],
  images: [],
  description: '',
  attachment: null,
  publishStatus: 'Published',
  publishedDate: null,
  publishEndDate: null,
  shopId: null,
  commerceItemCategoryId: null,
  caution: '',
};

export const COURSE_SCHEDULE_FORM_SCHEMA = z
  .object({
    commerceScheduleId: z
      .number()
      .optional(),
    commerceScheduleTitle: z
      .string()
      .min(1, '必填')
      .max(200, '超出字數上限'),
    date: z
      .date()
      .nullable()
      .refine((data) => (data ?? 0), { message: '必填' }),
    startTime: z
      .object({
        hour: z.string(),
        minute: z.string(),
      })
      .refine((val) => (val?.hour && val?.minute), { message: '必填' }),
    endTime: z
      .object({
        hour: z.string(),
        minute: z.string(),
      })
      .refine((val) => (val?.hour && val?.minute), { message: '必填' }),
    isOnline: z
      .string(),
    locationOrZoomRoom: z
      .object({
        location: z
          .string()
          .max(200, '超出字數上限'),
        zoomRoomNumber: z
          .string()
          .max(30, '超出字數上限'),
        zoomRoomPassword: z
          .string()
          .max(30, '超出字數上限'),
      }),
    pointEarnAfterCourseCheckIn: z
      .string()
      .refine((val) => !val || Number(val) > 0, { message: '請輸入正數' }),
    afterCheckInSurveyId: z
      .number()
      .nullable(),
    material: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
  })
  .refine(({ startTime, endTime }) => {
    if (startTime && endTime) {
      if (parseInt(startTime.hour, 10) < parseInt(endTime.hour, 10)) {
        return true;
      }
      if (parseInt(startTime.hour, 10) === parseInt(endTime.hour, 10)) {
        return parseInt(startTime.minute, 10) < parseInt(endTime.minute, 10);
      }
    }
    return false;
  }, { message: '結束時間不能早於開始時間', path: ['endTime'] })
  .refine(({ isOnline, locationOrZoomRoom }) => {
    if (isOnline === 'false') {
      return !!(locationOrZoomRoom.location);
    }
    return true;
  }, { message: '必須填寫地址', path: ['locationOrZoomRoom', ''] })
  .refine(({ isOnline, locationOrZoomRoom }) => {
    if (isOnline === 'true') {
      return !!locationOrZoomRoom.zoomRoomNumber && !!locationOrZoomRoom.zoomRoomPassword;
    }
    return true;
  }, { message: '必須填寫Zoom房間號碼及密碼', path: ['locationOrZoomRoom', ''] });

export type CourseScheduleFormDataType = z.infer<typeof COURSE_SCHEDULE_FORM_SCHEMA>;

export const COURSE_DETAIL_INFO_FORM_SCHEMA = z
  .object({
    courseCode: z
      .string()
      .min(1, '必填'),
    contactPerson: z
      .string()
      .min(1, '必填'),
    contactPhoneNumber: z
      .string()
      .min(1, '必填')
      .regex(PHONE_REGEX, '請輸入正確的手機號碼'),
    openForSaleDate: z
      .date()
      .nullable()
      .refine((data) => data, { message: '必填' }),
    discontinueDate: z
      .date()
      .nullable()
      .refine((data) => data, { message: '必填' }),
    pointEarnAfterApply: z
      .string()
      .refine((val) => !val || Number(val) > 0, { message: '請輸入正數' }),
    afterPaymentAttachment: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
    courseDetailId: z
      .number()
      .optional(),
    CommerceSchedules: z
      .array(COURSE_SCHEDULE_FORM_SCHEMA),
  })
  .refine(
    ({ openForSaleDate, discontinueDate }) => dayjs(openForSaleDate).isSameOrBefore(dayjs(discontinueDate)),
    { message: '截止日期不能早於開始日期', path: ['discontinueDate'] },
  );

export type CourseDetailInfoFormDataType = z.infer<typeof COURSE_DETAIL_INFO_FORM_SCHEMA>;

export const INITIALIZED_COURSE_SCHEDULE: CourseScheduleFormDataType = {
  commerceScheduleTitle: '',
  date: null,
  startTime: {
    hour: '',
    minute: '',
  },
  endTime: {
    hour: '',
    minute: '',
  },
  isOnline: 'false',
  locationOrZoomRoom: {
    location: '',
    zoomRoomNumber: '',
    zoomRoomPassword: '',
  },
  pointEarnAfterCourseCheckIn: '',
  afterCheckInSurveyId: null,
  material: null,
};

export const INITIALIZED_COURSE_DETAIL_INFO: CourseDetailInfoFormDataType = {
  courseCode: '',
  contactPerson: '',
  contactPhoneNumber: '',
  openForSaleDate: null,
  discontinueDate: null,
  pointEarnAfterApply: '',
  afterPaymentAttachment: null,
  CommerceSchedules: [INITIALIZED_COURSE_SCHEDULE],
};

export const COURSE_PAYMENT_CARD_SCHEMA = z
  .object({
    commercePurchaseOptionId: z
      .number()
      .optional(),
    paymentType: z
      .custom<PaymentOptionType>(),
    cash: z
      .string(),
    point: z
      .string(),
    courseQuota: z
      .string(),
    isAllowMember: z
      .boolean(),
    memberSurveyOption: z
      .custom<MemberSurveyOption>(),
    memberSurveyId: z
      .number()
      .nullable(),
    allowPreAgent: z
      .boolean(),
    preAgentSurveyOption: z
      .custom<SurveyOption>(),
    preAgentSurveyId: z
      .number()
      .nullable(),
    isAllowWaitList: z
      .boolean(),
    waitListQuota: z
      .string()
      .regex(/^[1-9]\d*$/, '請輸入正整數')
      .or(z.literal('')),
  })
  .refine(({ waitListQuota, isAllowWaitList }) => !isAllowWaitList || waitListQuota, {
    message: '必填',
    path: ['waitListQuota'],
  })
  .refine(({ isAllowMember, allowPreAgent }) => isAllowMember || allowPreAgent, {
    message: '請開放會員或準成員報名',
    path: ['isAllowMember'],
  })
  .refine(({ isAllowMember, allowPreAgent }) => isAllowMember || allowPreAgent, {
    message: '請開放會員或準成員報名',
    path: ['allowPreAgent'],
  })
  .refine(({ paymentType, cash }) => !(paymentType === 'cash' || paymentType === 'cashAndPoint') || Number(cash) >= 4, {
    message: '最低金額為HKD$4',
    path: ['cash'],
  })
  .refine(({ paymentType, point }) => !(paymentType === 'point' || paymentType === 'cashAndPoint') || Number(point) > 0, {
    message: '最低積分為1分',
    path: ['point'],
  })
  .refine((
    { memberSurveyOption, memberSurveyId },
  ) => memberSurveyOption === 'Unessential' || (memberSurveyId ?? 0) > 0, {
    message: '必選',
    path: ['memberSurveyId'],
  })
  .refine((
    { preAgentSurveyOption, preAgentSurveyId },
  ) => preAgentSurveyOption === 'Unessential' || (preAgentSurveyId ?? 0) > 0, {
    message: '必選',
    path: ['preAgentSurveyId'],
  });

export type CoursePaymentCardFormDataType = z.infer<typeof COURSE_PAYMENT_CARD_SCHEMA>;

export const COURSE_PAYMENT_INFO_FORM_SCHEMA = z
  .object({
    isApplyPerLesson: z
      .boolean(),
    PaymentCard: COURSE_PAYMENT_CARD_SCHEMA,
    acceptDonation: z
      .boolean(),
    surveyId: z
      .number()
      .nullable(),
    perOrderMin: z
      .string()
      .regex(/^\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perOrderMax: z
      .string()
      .regex(/^[1-9]\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perUserTotalMin: z
      .string()
      .regex(/^\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perUserTotalMax: z
      .string()
      .regex(/^[1-9]\d*$/, '請輸入正整數')
      .or(z.literal('')),
  })
  .refine((
    { perOrderMin, perOrderMax },
  ) => !perOrderMin
    || !perOrderMax
        || parseInt(perOrderMin, 10) <= parseInt(perOrderMax, 10), {
    message: '最少數量不能大於最多數量',
    path: ['perOrderMin'],
  })
  .refine(
    (
      { perUserTotalMin, perUserTotalMax },
    ) => !perUserTotalMin
        || !perUserTotalMax
            || parseInt(perUserTotalMin, 10)
            <= parseInt(perUserTotalMax, 10),
    {
      message: '最少數量不能大於最多數量',
      path: ['perUserTotalMin'],
    },
  );

export type CoursePaymentInfoFormDataType = z.infer<typeof COURSE_PAYMENT_INFO_FORM_SCHEMA>;

export const INITIALIZED_COURSE_PAYMENT_CARD: CoursePaymentCardFormDataType = {
  commercePurchaseOptionId: undefined,
  paymentType: 'free',
  cash: '',
  point: '',
  courseQuota: '',
  isAllowMember: true,
  memberSurveyOption: 'Unessential',
  memberSurveyId: null,
  allowPreAgent: false,
  preAgentSurveyOption: 'Unessential',
  preAgentSurveyId: null,
  isAllowWaitList: false,
  waitListQuota: '',
};

export const INITIALIZED_COURSE_PAYMENT_INFO: CoursePaymentInfoFormDataType = {
  isApplyPerLesson: false,
  PaymentCard: INITIALIZED_COURSE_PAYMENT_CARD,
  acceptDonation: false,
  surveyId: null,
  perOrderMin: '',
  perOrderMax: '',
  perUserTotalMin: '',
  perUserTotalMax: '',
};

export const COURSE_FORM_SCHEMA = z
  .object({
    reviewStatus: z
      .custom<ReviewStatusType>()
      .optional(),
    remarks: z
      .string(),
    basicInfo: COURSE_BASIC_INFO_FORM_SCHEMA,
    detailInfo: COURSE_DETAIL_INFO_FORM_SCHEMA,
    paymentInfo: COURSE_PAYMENT_INFO_FORM_SCHEMA,
    permission: COMMERCE_ITEM_PERMISSION_FORM_SCHEMA,
    pushNotification: COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA,
  })
  .superRefine(({
    reviewStatus, permission, paymentInfo: { PaymentCard },
  }, ctx) => {
    if (reviewStatus !== '草稿') {
      validatePermission(permission, ctx);
      if (!PaymentCard.courseQuota) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '必填',
          path: ['paymentInfo', 'PaymentCard', 'courseQuota'],
        });
      } else if (!PaymentCard.courseQuota.match(/^[1-9]\d*$/)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '請輸入正整數',
          path: ['paymentInfo', 'PaymentCard', 'courseQuota'],
        });
      }
    }
  });

export type CourseFormDataType = z.infer<typeof COURSE_FORM_SCHEMA>;

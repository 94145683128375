/* eslint-disable tailwindcss/no-custom-classname */
import 'react-quill/dist/quill.snow.css';
import 'app/assets/scss/form.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useController, useForm } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import { toast } from 'react-toastify';

import { uploadImage } from 'app/apis/UploadServices';
import EmailIcon from 'app/assets/images/email.svg';
import LOADING from 'app/assets/images/loading.gif';
import PhoneIcon from 'app/assets/images/phone.svg';
import Button from 'app/components/Button';
import { Form, InputField } from 'app/components/Form';
import Modal from 'app/components/Modal';
import { IMAGE_SIZE, IMAGE_SIZE_IN_MB } from 'app/configs';
import { LINK_FORM_SCHEMA, LinkFormDataType } from 'app/constants';
import { HTMLLinkType } from 'app/models/Common';
import cn from 'app/utils/ClassName';
import { getLinkName } from 'app/utils/FormUtils';

// font size
const fontSizeArr = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '30px',
];

// line height
const lineHeightArr = ['1', '1.2', '1.5', '2', '3'];
const Parchment = Quill.import('parchment');
const LineStyle = new Parchment.Attributor.Style('lineHeight', 'line-height', {
  scope: Parchment.Scope.INLINE,
  whiteList: lineHeightArr,
});

const Size = Quill.import('attributors/style/size');
const Align = Quill.import('attributors/style/align');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
Quill.register(Align, true);
Quill.register(LineStyle, true);

// text and bg colors
const COLOR_ARR = [
  '#4C4687',
  '#947CD9',
  '#FFD59D',
  '#3D3D3D',
  '#DEDEDE',
  '#B2AEB5',
  '#737373',
  '#C6C1FF',
  '#FAFAFA',
  '#FO4C4D',

  '#000000',
  '#222222',
  '#444444',
  '#666666',
  '#9B9B9B',
  '#cccccc',
  '#ffffff',

  '#e74c3c',
  '#f39c12',
  '#fdda00',
  '#61a951',
  '#07a9fe',
  '#8e44ad',
  '#f32784',

  '#c0392b',
  '#d35400',
  '#f1c40f',
  '#16a085',
  '#003ba5',
  '#6F1E51',
  '#B53471',
];

const BACK_GROUND_COLOR_ARR = [
  '#FF768F1A',
  '#F6FCFF',
  '#FFFFFF',
  '#e74c3c',
  '#f39c12',
  '#fdda00',
  '#61a951',
  '#07a9fe',
  '#8e44ad',
  '#f32784',
];

interface HTMLFieldProps {
  id?: string;
  name: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
}

const icons = Quill.import('ui/icons');
icons.emails = `<img src=${EmailIcon} width="18" height="18" />`;
icons.tel = `<img src=${PhoneIcon} width="16" height="14" />`;

export const HTMLField = ({
  id,
  name,
  defaultValue,
  label,
  placeholder,
  className,
  labelClassName,
  disabled = false,
}: HTMLFieldProps) => {
  const { field, fieldState } = useController({
    name,
    defaultValue,
  });

  const [linkVisibleType, setLinkVisibleType] = useState<HTMLLinkType | null>(
    null,
  );
  const quillRef = useRef<ReactQuill | null>(null);

  const imageHandler = () => {
    // get editor
    const editor = quillRef?.current?.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('multiple', 'true');
    input.click();

    input.onchange = async () => {
      const files = input?.files?.length ? [...input.files] : [];
      if (files.some((file) => file.size > IMAGE_SIZE)) {
        toast.warning(`圖片不可大於${IMAGE_SIZE_IN_MB}MB`);
        return;
      }
      if (editor) {
        const range = editor.getSelection();

        if (range) {
          editor.insertEmbed(range.index, 'image', LOADING);
          editor.setSelection(range);
          const imagePromises = files.map(async (file) => {
            const res = await uploadImage(file);
            return res;
          });

          try {
            const imageArray = await Promise.all(imagePromises);
            editor.deleteText(range.index, 1);
            imageArray.map((image) => editor.insertEmbed(
              range.index,
              'image',
              `${image.url}/original/${image.fileName}`,
            ));

            editor.setSelection({
              index: range.index + imageArray.length,
              length: range.length,
            });
          } catch (err) {
            editor.deleteText(range.index, 1);
            toast.warning('上載圖片失敗，請重試。');
          }
        }
      }
    };
  };

  // link button onClick
  const linkHandler = () => {
    // get editor
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('link');
      }
    }
  };

  // Video Handler
  // const videoHandler = () => {
  //   setVideoLinkVisible(true);
  // };

  const emailHandler = () => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('email');
      }
    }
  };

  const telHandler = () => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('tel');
      }
    }
  };

  const linkOnSubmit = ({ link }: { link: string }) => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      editor.format('link', link);
      setLinkVisibleType(null);
    }
  };

  // quill modules
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ lineHeight: lineHeightArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ align: [] }],
          [{ color: COLOR_ARR }, { background: BACK_GROUND_COLOR_ARR }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image', 'video', 'emails', 'tel'],
        ],
        handlers: {
          image: imageHandler,
          link: linkHandler,
          // video: videoHandler,
          emails: emailHandler,
          tel: telHandler,
        },
      },
    }),
    [],
  );

  const methods = useForm<LinkFormDataType>({
    defaultValues: {
      link: '',
      linkType: linkVisibleType as HTMLLinkType,
    },
    resolver: zodResolver(LINK_FORM_SCHEMA),
  });

  const onSubmit = (values: LinkFormDataType) => {
    const { link } = values;

    switch (linkVisibleType) {
      case 'link':
        linkOnSubmit({ link: `${link}` });
        break;
      case 'email':
        linkOnSubmit({ link: `mailto:${link}` });
        break;
      case 'tel':
        linkOnSubmit({ link: `tel:${link}` });
        break;
      default:
    }
  };

  useEffect(() => {
    methods.reset();
    methods.setValue('linkType', linkVisibleType);
  }, [linkVisibleType, methods]);

  return (
    <div
      className={cn(
        'space-y-[8px]',
        className,
      )}
      id={name}
    >
      {label ? (
        <label
          className={cn(
            'relative block text-[14px] font-semibold leading-[16.94px] text-text-primary',
            labelClassName,
          )}
        >
          {label}
        </label>
      ) : null}

      {/* html editor */}
      <div className="html-editor" id={id}>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={field.value}
          modules={disabled ? { toolbar: false } : modules}
          readOnly={disabled}
          onChange={(html) => {
            field.onChange(html.replace(/src="http:\/\//g, 'src="https://'));
          }}
          placeholder={placeholder}
          scrollingContainer="#scrolling-container"
          className={cn(
            'bg-white',
            disabled ? 'cursor-not-allowed !bg-bg-disabled rounded-[6px]' : '',
          )}
        />
        {fieldState.error?.message ? (
          <div className="mt-[8px] text-left text-[12px] font-normal leading-[16px] tracking-[0.01em] text-brand-attention">
            {fieldState.error.message}
          </div>
        ) : (
          <div className="h-[22.32px]" />
        )}
      </div>

      {linkVisibleType ? (
        <Modal
          visible={!!linkVisibleType}
          title={`輸入${getLinkName(linkVisibleType)}`}
          onClose={() => setLinkVisibleType(null)}
        >
          <Form
            methods={methods}
            innerClassName="!p-0 !pt-[16px]"
          >
            <div className="col-span-2">
              <InputField
                name="link"
                label={getLinkName(linkVisibleType)}
                placeholder={
                  linkVisibleType === 'link'
                    ? 'https://google.com'
                    : `請輸入有效的${getLinkName(linkVisibleType)}格式`
                }
              />
              <Button
                variant="contained"
                color="primary"
                containerStyle="col-span-2 w-full py-[6px] mt-[10px]"
                type="submit"
                onClick={methods.handleSubmit(onSubmit)}
              >
                確定
              </Button>
            </div>
          </Form>
        </Modal>
      ) : null}
    </div>
  );
};

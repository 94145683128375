import { ReactNode } from 'react';

import { Header, MenuDrawer, SideBar } from 'app/components';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { HEADER_HEIGHT } from 'app/constants';
import { useLeadingPathName } from 'app/hooks';
import { useLayoutStore } from 'app/stores';

export interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const {
    isGlobalLoading,
  } = useLayoutStore();
  const currentPath = useLeadingPathName();

  return (
    <div className="relative h-screen overflow-hidden bg-bg-primary">
      <MenuDrawer currentPath={currentPath} />

      <Header />
      <div
        className="flex w-full"
        style={{ height: `calc(100% - ${HEADER_HEIGHT}px)` }}
      >

        <SideBar currentPath={currentPath} />
        <ErrorBoundary>
          {children}
        </ErrorBoundary>

      </div>

      {/* loading spinner */}
      {isGlobalLoading && (
        <div className="absolute inset-0 backdrop-brightness-50">
          Loading...
        </div>
      )}
    </div>
  );
};

import { z } from 'zod';

export const LOGIN_FORM_SCHEMA = z
  .object({
    loginName: z
      .string()
      .min(1, { message: '必填' }),
    password: z
      .string()
      .min(1, { message: '必填' }),
  })
  .required();

export type LoginFormDataType = z.infer<typeof LOGIN_FORM_SCHEMA>;

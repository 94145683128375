import ErrorBoundary from './components/ErrorBoundary';
import { Providers } from './components/Providers';
import { Routes } from './routes';

export const App = () => (
  <ErrorBoundary>
    <Providers>
      <Routes />
    </Providers>
  </ErrorBoundary>
);

import { Dialog, Transition } from '@headlessui/react';
import {
  Fragment, useRef, useState,
} from 'react';
import { IoClose } from 'react-icons/io5';
import 'swiper/css';
import 'swiper/css/navigation';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import PrismaZoom from 'react-prismazoom';
import { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FormPreviewItem, LocalFile } from 'app/models/Common';

import { Image } from '../Image';

interface Props {
  visible: boolean;
  images: (LocalFile | FormPreviewItem)[];
  initialIndex: number;
  onClose: () => void;
}

export const ImagePreview = ({
  visible,
  images,
  initialIndex,
  onClose,
}: Props) => {
  const swiperRef = useRef<SwiperType>();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/85" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-screen min-w-[100vw] text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex h-screen w-screen flex-col align-middle shadow-xl transition-all">
                <div className="fixed top-0 z-20 flex w-full justify-center py-[20px]">
                  <button
                    type="button"
                    className="absolute right-0 top-0 p-[20px]"
                    onClick={onClose}
                  >
                    <IoClose className="size-[25px]" color="#A7A7A7" />
                  </button>
                </div>

                <Swiper
                  className="size-full"
                  initialSlide={initialIndex}
                  loop
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  onSlideChange={(swiper) => {
                    setCurrentIndex(swiper.realIndex);
                  }}
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={`image-${index}`}>
                      <div className="flex h-full items-center justify-center">
                        <PrismaZoom className="!cursor-zoom-in">
                          {index === currentIndex ? (
                            <Image
                              src={image.preview ?? ''}
                              alt={image.name}
                              className="h-[90vh] w-[90vw] object-scale-down"
                            />
                          ) : null}
                        </PrismaZoom>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <button
                    className="z-10"
                    type="button"
                    onClick={() => {
                      swiperRef.current?.slidePrev();
                    }}
                  >
                    <MdNavigateBefore className="size-[60px] text-text-light" />
                  </button>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <button
                    className="z-10"
                    type="button"
                    onClick={() => {
                      swiperRef.current?.slideNext();
                    }}
                  >
                    <MdNavigateNext className="z-10 size-[60px] text-text-light" />
                  </button>
                </div>
                <div className="text-[18px] leading-[25px] text-text-light">
                  {currentIndex + 1}
                  /
                  {images.length}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

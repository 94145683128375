import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { z } from 'zod';

dayjs.extend(isSameOrBefore);
export const MAINTENANCE_FORM_SCHEMA = z
  .object({
    title: z
      .string()
      .min(1, { message: '必填' })
      .max(255, { message: '超出字數上限' }),
    startDate: z
      .date({ required_error: '必填' })
      .nullable(),
    endDate: z
      .date({ required_error: '必填' })
      .nullable(),
    startTime: z
      .object({
        hour: z.string(),
        minute: z.string(),
      })
      .refine((val) => (val.hour && val.minute), { message: '必填' }),
    endTime: z
      .object({
        hour: z.string(),
        minute: z.string(),
      })
      .refine((val) => (val.hour && val.minute), { message: '必填' }),
  })
  .refine(({ startDate }) => startDate !== null, { message: '必填', path: ['startDate'] })
  .refine(({ endDate }) => endDate !== null, { message: '必填', path: ['endDate'] })
  .refine((data) => dayjs(data.startDate).isSameOrBefore(dayjs(data.endDate), 'day'), {
    message: '結束時間不能早於開始時間',
    path: ['endDate'],
  })
  .refine(
    ({
      startDate, endDate, startTime, endTime,
    }) => {
      if (startDate && endDate) {
        return dayjs(`${endDate.toDateString()} ${endTime.hour}:${endTime.minute}`)
          .isAfter(
            dayjs(`${startDate.toDateString()} ${startTime.hour}:${startTime.minute}`),
          );
      }
      return false;
    },
    {
      message: '結束時間不能早於開始時間',
      path: ['endTime'],
    },
  );

export type MaintenanceFormDataType = z.infer<typeof MAINTENANCE_FORM_SCHEMA>;

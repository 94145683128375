import { Disclosure } from '@headlessui/react';
import 'rc-drawer/assets/index.css';
import 'app/assets/scss/drawer.scss';
import Drawer from 'rc-drawer';
import { TbChevronRight } from 'react-icons/tb';
import { Link, NavLink } from 'react-router-dom';

import Logo from 'app/assets/images/logo.png';
import { ROUTES, SIDE_BAR_WIDTH } from 'app/constants';
import { MENUS } from 'app/constants/Menu';
import { useLayoutStore } from 'app/stores';
import cn from 'app/utils/ClassName';

import { Image } from '../Image';

const NAV_FONT_STYLE_CLASS = 'flex w-full justify-between bg-bg-transparent py-3 text-left text-[14px] font-normal transition-colors duration-300';
const NAV_HOVER_FONT_STYLE_CLASS = 'hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75';

export const MenuDrawer = ({ currentPath }: { currentPath: string }) => {
  const { isDrawerOpen, closeDrawer } = useLayoutStore();

  const hideOtherDisclosuresHandle = (id: string) => {
    if (document.querySelectorAll('button[data-headlessui-state="open"]')) {
      const buttons = Array.from(
        document.querySelectorAll('button[data-headlessui-state="open"]'),
      ) as HTMLButtonElement[];
      buttons.forEach((button) => {
        if (button?.id !== id) {
          button?.click();
        }
      });
    }
  };

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={() => { closeDrawer(); }}
      placement="left"
      maskMotion={{
        motionAppear: true,
        motionName: 'mask-motion',
      }}
      motion={{
        motionAppear: true,
        motionName: 'panel-motion-left',
      }}
      styles={{
        wrapper: {
          width: SIDE_BAR_WIDTH,
        },
      }}
    >
      <div className="h-full bg-white px-4 pb-4">
        <div className="mx-auto w-full max-w-md bg-transparent">
          <Link
            to={ROUTES.HOME}
            className="flex w-full items-center justify-start py-[12px]"
          >
            <Image src={Logo} alt="logo" width={150} />
          </Link>
          {currentPath
            && MENUS.map((menu) => (menu.children ? (
              <Disclosure
                key={menu.key}
                defaultOpen={currentPath === menu.key}
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      onClick={() => hideOtherDisclosuresHandle(menu.key)}
                      className={cn(
                        NAV_FONT_STYLE_CLASS,
                        NAV_HOVER_FONT_STYLE_CLASS,
                        `${
                          currentPath === menu.key
                            ? 'text-brand-primary'
                            : 'text-text-secondary'
                        }`,
                      )}
                    >
                      <div className="flex items-center">
                        {menu.icon}
                        <div className="ml-2">{menu.title}</div>
                      </div>
                      <TbChevronRight
                        className={`${open ? 'rotate-90' : ''} size-5`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="flex flex-col pl-[28px] text-[14px] font-normal text-text-secondary">
                      {menu.children.map((child) => (
                        <NavLink
                          key={child.key}
                          to={child.href}
                          onClick={() => {
                            closeDrawer();
                          }}
                          className={({ isActive }) => cn(
                            NAV_FONT_STYLE_CLASS,
                            NAV_HOVER_FONT_STYLE_CLASS,
                            `${
                              isActive
                                ? 'text-brand-primary'
                                : 'text-text-secondary'
                            }`,
                          )}
                        >
                          {child.title}
                        </NavLink>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ) : (
              <NavLink
                key={menu.key}
                to={menu.href}
                onClick={() => {
                  hideOtherDisclosuresHandle('');
                  closeDrawer();
                }}
                className={({ isActive }) => cn(
                  NAV_FONT_STYLE_CLASS,
                  NAV_HOVER_FONT_STYLE_CLASS,
                  `${
                    isActive ? 'text-brand-primary' : 'text-text-secondary'
                  }`,
                )}
              >
                <div className="flex items-center">
                  {menu.icon}
                  <div className="ml-2">{menu.title}</div>
                </div>
              </NavLink>
            )))}
        </div>
      </div>
    </Drawer>
  );
};

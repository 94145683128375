import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import 'app/assets/scss/base.scss';
import { App } from './app';

Sentry.init({
  dsn: 'https://b660d79c4ec04699b100626e3bd475a3@sentry.appicidea.com/32',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_REACT_APP_SENTRY_ENVIRONMENT || 'development',
  release: import.meta.env.VITE_REACT_APP_SENTRY_REL || 'default',
  enabled: import.meta.env.VITE_REACT_APP_NODE_ENV !== 'development',
  beforeSend: (event: any) => {
    if (window?.location?.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

import clsx from 'clsx';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useController } from 'react-hook-form';
import { IoMdCloudUpload } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import Button from 'app/components/Button';
import { Image as ImageComponent } from 'app/components/Image';
import { IMAGE_SIZE, IMAGE_SIZE_IN_MB } from 'app/configs';
import { LocalFile } from 'app/models/Common';
import { getImageURL, formatBytes } from 'app/utils';
import cn from 'app/utils/ClassName';

import { ImagePreview } from '../ImagePreview';

interface Props {
  name: string;
  defaultValue?: string;
  label?: string;
  multiple?: boolean;
  className?: string;
  labelClassName?: string;
  aspectRatio?: number;
  isDisable?: boolean;
}

export const ImageField = ({
  name,
  defaultValue,
  label,
  multiple = false,
  className,
  labelClassName,
  aspectRatio,
  isDisable,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const { field, fieldState } = useController({
    name,
    defaultValue,
  });

  const onDelete = (original: any, index: number) => {
    const temp = [...original];
    temp.splice(index, 1);
    if (temp.length > 0) {
      field.onChange(temp);
    } else {
      field.onChange([]);
    }
  };

  const onDrop = (files: any) => {
    let images: LocalFile[] = [];
    if (field.value) {
      images = [...field.value];
    }
    files.forEach((file: any) => {
      if (file.size > IMAGE_SIZE) {
        toast.warning(`圖片不可大於${IMAGE_SIZE_IN_MB}MB`);
      } else {
        const image = new Image();
        const tempSrc = URL.createObjectURL(file);
        image.src = tempSrc;
        // eslint-disable-next-line no-param-reassign
        file.preview = tempSrc;
        // eslint-disable-next-line no-param-reassign
        file.formattedSize = formatBytes(file.size);
        if (multiple) {
          images.push(file);
        } else {
          images = [file];
        }

        field.onChange(images);
      }
    });
  };

  return (
    <div className={clsx('relative space-y-[8px]', className)} id={name}>
      {label ? (
        <label
          htmlFor={name}
          className={clsx(
            'relative block text-[14px] font-semibold leading-[16.94px] text-text-primary',
            labelClassName,
          )}
        >
          {label}
        </label>
      ) : (
        <div />
      )}
      <Dropzone
        multiple={multiple}
        onDrop={(acceptedFiles) => {
          onDrop(acceptedFiles);
        }}
        accept={{ 'image/*': [] }}
        disabled={isDisable}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={cn(
            'rounded-[6px] border-2 border-dashed border-border-brand bg-white',
            isDisable && 'cursor-not-allowed',
          )}
          >
            <div
              className="mt-[8px] flex min-h-[230px] w-full flex-col items-center justify-center text-[24px] focus:outline-none"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <IoMdCloudUpload className="mb-[12px] size-[40px] text-text-light" />
              <h4 className="text-text-light">點擊或拉動圖片檔案到此處</h4>
              <p className="text-[14px] text-text-light">
                圖片不能超過
                {IMAGE_SIZE_IN_MB}
                MB
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      <div className={`${field.value ? 'mt-[12px]' : ''}`} id="file-previews">
        {field.value
          ? field.value.map((file: LocalFile, i: number) => (
            <div
              className={clsx(
                'mb-0 mt-1 flex flex-row items-center justify-between rounded-[6px] border p-2 shadow-none',
                file.size > IMAGE_SIZE ? 'border-brand-attention' : '',
              )}
              key={`${i}-file`}
            >
              <div className="flex flex-row items-center space-x-[20px]">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setSelectedIndex(i);
                    setVisible(true);
                  }}
                >
                  <ImageComponent
                    src={file.preview || ''}
                    alt="preview"
                    width={120}
                    style={{ aspectRatio: aspectRatio || 16 / 9 }}
                    className="w-[120px] object-contain"
                  />
                </Button>

                <div>
                  <div
                    className={clsx(
                      'whitespace-pre-line break-all font-semibold',
                      file.size > IMAGE_SIZE
                        ? 'text-brand-attention'
                        : 'text-text-primary',
                    )}
                  >
                    {file.name}
                  </div>
                  <p
                    className={clsx(
                      'text-[14px] font-light',
                      file.size > IMAGE_SIZE
                        ? 'text-brand-attention'
                        : 'text-text-primary',
                    )}
                  >
                    {file.formattedSize}
                  </p>
                </div>
              </div>
              <div className="flex justify-end self-center">
                {!file.size ? (
                  <>
                    <a
                      href={getImageURL(file.name, 'original')}
                      target="_blank"
                      className="group p-[10px]"
                      download={file.name}
                      rel="noopener noreferrer"
                      data-tooltip-id="download-media"
                    >
                      <RiDownloadCloud2Line className="size-[20px] text-text-light group-hover:text-text-primary" />
                    </a>
                    <Tooltip id="download-media" place="top" content="下載" />
                  </>
                ) : null}
                {!isDisable ? (
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="p-[10px] group"
                    onClick={() => onDelete(field.value, i)}
                    tooltipId="delete-media"
                  >
                    <MdDeleteOutline className="size-[20px] text-text-light group-hover:text-text-primary" />
                    <Tooltip id="delete-media" place="top" content="刪除" />
                  </Button>
                ) : null}
              </div>
            </div>
          ))
          : null}
      </div>
      {fieldState.error?.message ? (
        <div className="text-left text-[12px] font-normal leading-[16px] tracking-[0.01em] text-brand-attention">
          {fieldState.error.message}
        </div>
      ) : (
        <div className="h-[16px]" />
      )}

      <ImagePreview
        visible={visible}
        initialIndex={selectedIndex}
        onClose={() => {
          setVisible(false);
        }}
        images={field.value || []}
      />
    </div>
  );
};

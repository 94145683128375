import { isAxiosError } from 'axios';
import * as qs from 'qs';
import * as mime from 'react-native-mime-types';
import { toast } from 'react-toastify';

import {
  copyDocument,
  deleteOneFile,
  FileParams,
  getOneFileType,
  getShareToken,
  getShareTokenFileParams,
  moveRenameDocument,
  uploadOneFileToDrive,
} from 'app/apis/DriveServices';
import { API_URL, WEB_APP_URL } from 'app/configs';
import { FormPreviewItem } from 'app/models/Common';
import {
  DriveFileResponse, FolderType, GetOneFileTypeRes, ShareDriveTokenResponse,
} from 'app/models/Drive';

export const getDriveFilePreviewLink = (
  folderType: FolderType,
  dir: string | undefined,
  fileName: string,
  shopId?: string | number,
) => {
  const queries = {
    folderType,
    driveType: shopId ? 'shop' : 'public',
    shopId,
    path: dir ? `${dir}/${fileName}` : fileName,
  };
  return `${API_URL}/v2/drive/file?${qs.stringify(queries)}`;
};

export const isVideoFile = (name: string): boolean => mime.lookup(name)
  ?.toString().startsWith('video') ?? false;

export const isImageFile = (name: string): boolean => mime.lookup(name)
  ?.toString().startsWith('image') ?? false;

const getShareDrivePath = (
  data: ShareDriveTokenResponse,
  file?: GetOneFileTypeRes,
) => {
  const encodedPath = encodeURIComponent(`${data.path}`);
  return `${WEB_APP_URL}/share?folder=${data.driveType}&type=${
    data.folderType
  }&path=${encodedPath}${data.shopId ? `&shopId=${data.shopId}` : ''}&token=${data.token}${
    file?.type && file?.type === 'File' ? `&fileName=${encodedPath}` : ''
  }${file?.url ? `&url=${file.url}` : ''}`;
};

export const shareOnPress = async (shareParams: ShareDriveTokenResponse) => {
  try {
    const response = await getShareToken(shareParams);
    const fileRes = await getShareTokenFileParams({
      ...shareParams,
      shareToken: response.token,
    });
    const link = getShareDrivePath(
      {
        ...shareParams,
        token: response.token,
      },
      fileRes,
    );
    navigator.clipboard.writeText(link);
    toast.success('己複製網址');
  } catch (error) {
    if (isAxiosError(error) && error?.message !== 'User did not share') {
      toast.error('請檢查你的網絡連接');
    }
  }
};

export const fileOnDelete = async ({
  folderType, driveType, dir, fileName, shopId,
}: DriveFileResponse & {
  shopId?: number | string;
}) => {
  try {
    await deleteOneFile({
      folderType,
      driveType,
      path: dir ? `${dir}/${fileName}` : fileName,
      shopId,
    });
    toast.success('刪除檔案成功');
    return 'success';
  } catch (err) {
    toast.error('刪除檔案失敗');
    return 'fail';
  }
};

export const fileOnRename = async (
  file: DriveFileResponse,
  image: (FormPreviewItem | File)[],
  newFileName: string,
  isCoverLoaded: boolean,
  shopId?: number | string,
) => {
  const {
    dir,
    fileName,
    folderType,
    driveType,
  } = file;

  if (image?.length && 'size' in image[0] && image[0].size) {
    const formData = new FormData();
    formData.append('file', image[0], `.${encodeURIComponent(newFileName)}`);
    if (isCoverLoaded) {
      await deleteOneFile({
        folderType,
        driveType,
        shopId,
        path: `${dir}/.${fileName}`,
      });
    }

    await uploadOneFileToDrive(
      {
        folderType,
        driveType,
        shopId,
        path: dir ?? '',
      },
      formData,
    );
  } else if (image?.length && 'preview' in image[0] && image[0].preview) {
    await moveRenameDocument({
      source: {
        folderType,
        driveType,
        shopId,
        path: `${dir}/.${fileName}`,
      },
      dest: {
        folderType,
        driveType,
        shopId,
        path: `${dir}/.${newFileName}`,
      },
    });
  }
  await moveRenameDocument({
    source: {
      folderType,
      driveType,
      shopId,
      path: `${dir}/${fileName}`,
    },
    dest: {
      folderType,
      driveType,
      shopId,
      path: `${dir}/${newFileName}`,
    },
  });
};

export const fileOnCopy = async ({
  dir, fileName, folderType, driveType, shopId,
}: DriveFileResponse & {
  shopId?: number | string;
}) => {
  let newName = '';
  const currentSource = {
    folderType,
    driveType,
    shopId,
    path: `${dir}/${fileName}`,
  };
  if (fileName.lastIndexOf('.') > -1) {
    newName = fileName.replace(/(\.[^.]+)$/, ' 的副本$1');
  } else {
    newName = `${fileName} 的副本`;
  }
  await copyDocument({
    source: currentSource,
    dest: {
      ...currentSource,
      path: `${dir}/${newName}`,
    },
  });
};

export const copyVideoLink = async ({
  folderType, driveType, dir, fileName, shopId,
}: DriveFileResponse & {
  shopId?: number | string;
}) => {
  try {
    const videoParams: FileParams = {
      folderType,
      driveType,
      shopId,
      path: `${dir ? `${dir}/` : ''}.${fileName}.m3u8`,
    };
    const res = await getOneFileType(videoParams);
    if (res) {
      // Is m3u8
      const { url } = res;
      if (url) {
        navigator.clipboard.writeText(url);
        toast.success('已複製網址至剪貼簿');
      } else {
        toast.error('複製影片網址失敗。');
      }
    } else {
      // Not m3u8
      const othersParams: FileParams = {
        folderType,
        driveType: shopId ? 'shop' : 'public',
        shopId,
        path: `${dir ? `${dir}/` : ''}${fileName}`,
      };
      const otherRes = await getOneFileType(othersParams);
      const { url } = otherRes;
      if (url) {
        navigator.clipboard.writeText(url);
        toast.success('已複製網址至剪貼簿');
      } else {
        toast.error('複製影片網址失敗。');
      }
      toast.success('已複製網址至剪貼簿');
    }
    return true;
  } catch {
    toast.error('複製影片網址失敗。');
    return false;
  }
};

export const copyImageLink = async ({
  folderType, driveType, dir, fileName, shopId,
}: DriveFileResponse & {
  shopId?: number | string;
}) => {
  try {
    const params: FileParams = {
      folderType,
      driveType,
      shopId,
      path: `${dir ? `${dir}/` : ''}${fileName}`,
    };
    const res = await getOneFileType(params);
    const { url } = res;
    if (url) {
      navigator.clipboard.writeText(url);
      toast.success('已複製網址至剪貼簿');
    } else {
      toast.error('複製圖片網址失敗。');
    }
    return true;
  } catch (err) {
    toast.error('複製圖片網址失敗。');
    return false;
  }
};

import dayjs from 'dayjs';
import { z } from 'zod';

import { EXCEL_SIZE } from 'app/configs';
import { StatementStatus } from 'app/models/Statement';

export const IMPORT_EXCEL_SCHEMA = z
  .object({
    statementTitle: z
      .string({
        required_error: '必填',
      }),
    uploadFile: z
      .array(
        z.instanceof(File),
        { required_error: '必填' },
      )
      .refine((files) => files[0].size <= EXCEL_SIZE, 'EXCEL檔案不可大於10MB')
      .refine((files) => files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      || files[0].type === 'application/vnd.ms-excel', '只接受Excel檔案'),
  });

export type ImportExcelDataType = z.infer<typeof IMPORT_EXCEL_SCHEMA>;

export const CHANGE_ALL_STATEMENTS_SCHEMA = z
  .object({
    startDate: z
      .date()
      .nullable()
      .optional(),
    endDate: z
      .date()
      .nullable()
      .optional(),
    family: z
      .string()
      .optional(),
    branchCode: z
      .string()
      .optional(),
    prevStatus: z
      .custom<StatementStatus>()
      .refine((status) => !!status, '必填'),
    currentStatus: z
      .custom<StatementStatus>()
      .optional(),
    dueDate: z
      .date()
      .nullable()
      .optional(),
    paymentRemarks: z
      .string()
      .optional(),
  })
  .refine(
    ({
      currentStatus,
      paymentRemarks,
    }) => currentStatus !== 'settled' || paymentRemarks,
    { message: '必填', path: ['paymentRemarks'] },
  )
  .refine(
    (data) => data.dueDate || data.currentStatus,
    { message: '必須設定 \'繳款狀態\' 或 \'需繳付日期\'', path: ['currentStatus'] },
  );

export type ChangeAllStatementsDataType = z.infer<typeof CHANGE_ALL_STATEMENTS_SCHEMA>;

export const STATEMENT_FORM_SCHEMA = z
  .object({
    userId: z
      .string({
        required_error: '必填',
      }),
    title: z
      .string({
        required_error: '必填',
      })
      .min(1, { message: '必填' })
      .max(255, { message: '超出字數上限' }),
    dueDate: z
      .date()
      .nullable()
      .optional(),
    statementDate: z
      .date({
        required_error: '必填',
      })
      .nullable()
      .refine((val) => val !== null, { message: '必填' }),
    status: z
      .custom<StatementStatus>()
      .refine((status) => !!status, '必填'),
    Fees: z.array(
      z.object({
        title: z
          .string({
            required_error: '必填',
          })
          .min(1, { message: '必填' })
          .max(100, { message: '超出字數上限' }),
        amount: z
          .string({
            required_error: '必填',
          })
          .min(1, { message: '必填' })
          .refine((value) => !!Number(value) || Number(value) === 0, { message: '請輸入有效金額' })
          .refine((value) => parseInt(value, 10) >= 0, { message: '不能負數' })
          .refine((value) => /^\d+(\.\d{0,2})?$/.test(value.toString()), { message: '最多兩位小數點' }),
        date: z
          .date({
            required_error: '必填',
          })
          .nullable(),
        remarks: z
          .string()
          .max(100, '超出字數上限')
          .nullable()
          .optional(),
        feeId: z
          .number()
          .optional(),
        statementId: z
          .number()
          .optional(),
        bringForwardFromStatementId: z
          .number()
          .nullable()
          .optional(),
        adminId: z
          .number()
          .optional(),
      }),
    ),
    paymentRemarks: z
      .string()
      .optional(),
  })
  .superRefine(({ Fees }, ctx) => {
    Fees.forEach(({ date }, i) => {
      if (!date) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: '必填',
          path: [`Fees[${i}].date`],
        });
      }
    });
  })
  .refine(
    (data) => {
      if (data.dueDate && data.statementDate) {
        return dayjs(data.dueDate).isAfter(dayjs(data.statementDate, 'day'));
      }
      return true;
    },
    { message: '需繳付日期不能早於帳單日期', path: ['dueDate'] },
  );

export type StatementFormDataType = z.infer<typeof STATEMENT_FORM_SCHEMA>;

export const SETTLE_FORM_SCHEMA = z
  .object({
    paymentRemarks: z
      .string({ required_error: '必填' })
      .min(2, '最少要2個字元')
      .max(100, '不可多於100個字元'),
  });

export type SettleFormDataType = z.infer<typeof SETTLE_FORM_SCHEMA>;

import { ReactNode } from 'react';

import cn from 'app/utils/ClassName';

interface Props {
  children: ReactNode;
  className?: string;
  containerClassName?: string;
}

export const Container = ({
  children,
  className,
  containerClassName,
}: Props) => (
  <div
    className={cn(
      'flex-1 max-w-[100dvw] non-mobile:p-[18px] overflow-hidden',
      containerClassName,
    )}
  >
    <div
      className={cn('bg-white non-mobile:rounded-lg non-mobile:shadow-card h-full flex flex-col overflow-hidden', className)}
    >
      {children}
    </div>
  </div>
);

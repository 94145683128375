import { HTMLLink, HTMLLinkType } from 'app/models/Common';

export const getLinkName = (linkType?: HTMLLinkType | null): string => {
  if (!linkType) {
    return '';
  }
  return HTMLLink[linkType];
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const validateYoutubeLink = (url: string) => {
  const regExp = /(http:|https:)\/\/(www.youtube.com)\/.+/;

  const match = url.match(regExp);
  return !!match?.length;
};

import { DevTool } from '@hookform/devtools';
import { ReactNode, CSSProperties } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { FORM_FOOTER_HEIGHT } from 'app/constants';
import { useLayoutStore } from 'app/stores';
import cn from 'app/utils/ClassName';

interface FormProps {
  methods: UseFormReturn<any, any, undefined>;
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  style?: CSSProperties;
  paddingBottom?: number;
}

export const Form = ({
  methods,
  className,
  children,
  style,
  innerClassName,
  paddingBottom,
}: FormProps) => {
  const { isOnDevMode } = useLayoutStore();
  return (
    <FormProvider {...methods}>
      {isOnDevMode ? (
        <DevTool control={methods.control} placement="bottom-left" />
      ) : null}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={cn(
          'flex flex-col h-full max-w-full relative overflow-hidden',
          className,
        )}
        style={style}
      >
        <div
          className={cn(
            'grid auto-rows-min grid-cols-1 non-mobile:grid-cols-2 gap-2 overflow-auto px-4 pt-8 h-full',
            innerClassName,
          )}
          style={{
            paddingBottom: paddingBottom ?? FORM_FOOTER_HEIGHT,
          }}
        >
          {children}
        </div>
      </form>
    </FormProvider>
  );
};

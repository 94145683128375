import { Transition, Dialog } from '@headlessui/react';
import {
  Fragment, useCallback, useEffect, useMemo, useState,
} from 'react';
import { FaRegCircleUser } from 'react-icons/fa6';
import {
  FcImageFile, FcVideoCall, FcFolder, FcFile,
} from 'react-icons/fc';
import { IoIosArrowBack } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';

import { FileParams, listFolder } from 'app/apis/DriveServices';
import { getOneShop } from 'app/apis/ShopServices';
import { isImageFile, isVideoFile } from 'app/helpers/Drive';
import { DriveFileResponse, FolderType, FolderTypeEnum } from 'app/models/Drive';
import { ShopResponse } from 'app/models/Shop';
import { getImageURL } from 'app/utils';
import cn from 'app/utils/ClassName';

import Button from '../Button';
import { Image } from '../Image';

interface Props {
  visible: boolean;
  loading?: boolean;
  submitText?: string;
  shopId?: string | number;
  selectAttachment?: boolean;
  onClose: () => void;
  onSubmit: (newDir: FileParams & { hiddenRoot?: string }) => Promise<void> | void;
}

const FileIcon = ({ fileName } :{ fileName: string }) => {
  if (isImageFile(fileName)) {
    return <FcImageFile className="size-[18px]" />;
  } if (isVideoFile(fileName)) {
    return <FcVideoCall className="size-[18px]" />;
  }
  return <FcFile className="size-[18px]" />;
};

export const DriveMoveToModal = ({
  visible,
  loading,
  submitText,
  shopId,
  selectAttachment = false,
  onClose,
  onSubmit,
}: Props) => {
  const [folderType, setFolderType] = useState<FolderType>();
  const [currentPath, setCurrentPath] = useState<string>('');
  const [driveData, setDriveData] = useState<DriveFileResponse[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [shopInfo, setShopInfo] = useState<ShopResponse>();

  useEffect(() => {
    const getShopInfo = async () => {
      if (shopId) {
        const data = await getOneShop(Number(shopId));
        setShopInfo(data);
      }
    };
    getShopInfo();
  }, [shopId]);

  useEffect(() => {
    if (!visible) {
      setCurrentPath('');
      setFolderType(undefined);
    }
  }, [visible]);

  const title = useMemo(() => {
    if (!folderType) {
      return '請選擇資料夾';
    }
    if (!currentPath) {
      return FolderTypeEnum[folderType];
    }
    return currentPath.split('/').at(-1);
  }, [currentPath, folderType]);

  const getDriveData = useCallback(async () => {
    if (folderType) {
      try {
        setFetchLoading(true);
        const data = await listFolder({
          folderType,
          driveType: shopId ? 'shop' : 'public',
          shopId: shopId || undefined,
          path: currentPath,
        });
        setDriveData(() => data);
      } catch {
        toast.error('找不到資料夾');
      } finally {
        setFetchLoading(false);
      }
    }
  }, [currentPath, folderType, shopId]);

  useEffect(() => {
    getDriveData();
  }, [getDriveData]);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'flex size-full min-w-max w-[50%] h-[80vh] flex-col overflow-visible rounded-[3px] bg-white text-left align-middle shadow-xl transition-all p-4',
                )}
              >
                <div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-[8px] pb-[12px]"
                  >
                    <Button
                      variant="text"
                      color="primary"
                      containerStyle="p-[8px]"
                      onClick={() => {
                        if (currentPath) {
                          setCurrentPath((prev) => {
                            const newPath = prev.split('/');
                            newPath.splice(-1, 1);
                            return newPath?.join('/');
                          });
                        } else {
                          setFolderType(undefined);
                          setDriveData([]);
                        }
                      }}
                    >
                      <IoIosArrowBack className="size-[24px]" />
                    </Button>
                    <div className="flex flex-1 items-center gap-[8px] text-lg font-medium leading-6 text-gray-900">
                      {shopInfo ? (
                        <div className="flex items-center gap-[4px]">
                          <Image
                            src={getImageURL(shopInfo.shopProfilePic)}
                            className="size-[24px] rounded-full object-contain"
                          />
                          <div className="leading-[16px]">{shopInfo.shopTitle}</div>
                        </div>
                      ) : null}
                      <div className="flex-1 leading-[16px]">{title}</div>
                    </div>
                    <Button
                      variant="text"
                      color="primary"
                      disabled={loading}
                      onClick={onClose}
                      containerStyle="p-[8px]"
                    >
                      <IoClose className="size-[24px]" color="#A7A7A7" />
                    </Button>
                  </Dialog.Title>
                </div>
                {!folderType ? (
                  <div className="flex flex-1 flex-col">
                    <Button
                      variant="text"
                      color="primary"
                      containerStyle="flex items-center p-[8px] gap-[4px] w-full"
                      onClick={() => { setFolderType('others'); }}
                    >
                      <FcFolder className="size-[18px]" />
                      {FolderTypeEnum.others}
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      containerStyle="flex items-center p-[8px] gap-[4px] w-full"
                      onClick={() => { setFolderType('private'); }}
                    >
                      <FaRegCircleUser className="size-[18px]" />
                      {FolderTypeEnum.private}
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-1 flex-col overflow-y-auto">
                    {driveData.filter((data) => data.type === 'Folder').map((data) => (
                      <Button
                        variant="text"
                        color="primary"
                        key={`${data.dir}/${data.fileName}`}
                        onClick={() => { setCurrentPath((prev) => prev.concat(`${prev ? '/' : ''}${data.fileName}`)); }}
                        containerStyle="flex items-center gap-[8px] py-[8px]"
                      >
                        <FcFolder />
                        <div className="text-text-primary">{data.fileName}</div>
                      </Button>
                    ))}
                    {driveData.filter((data) => data.type === 'File').map(({
                      dir, driveType, folderType: fileFolderType, fileName,
                    }) => (
                      <Button
                        variant="text"
                        color="primary"
                        disabled={!selectAttachment}
                        key={`${dir}/${fileName}`}
                        containerStyle={cn('flex items-center gap-[8px] py-[8px]', !selectAttachment ? 'text-text-disabled' : 'text-text-primary')}
                        onClick={() => {
                          const fullPath = dir ? `${dir}/${fileName}` : fileName;
                          onSubmit({
                            driveType,
                            folderType: fileFolderType,
                            shopId,
                            path: selectAttachment ? fileName : fullPath,
                            hiddenRoot: selectAttachment ? `${dir ? `${dir}/` : ''}` : undefined,
                          });
                          setDriveData([]);
                        }}
                      >
                        <FileIcon fileName={fileName} />
                        {fileName}
                      </Button>
                    ))}
                  </div>
                )}

                <div className="flex justify-between">
                  <div />
                  <Button
                    variant="text"
                    color="primary"
                    disabled={!folderType || (selectAttachment && !currentPath)}
                    loading={fetchLoading}
                    containerStyle="px-[12px] py-[8px] rounded-[5px]"
                    onClick={() => {
                      const pathArray = currentPath.split('/');
                      const fileName = pathArray.pop();
                      const hiddenRoot = pathArray.join('/');
                      onSubmit({
                        driveType: shopId ? 'shop' : 'public',
                        shopId,
                        folderType: folderType ?? 'others',
                        path: selectAttachment
                          ? fileName
                          : currentPath,
                        hiddenRoot: selectAttachment ? hiddenRoot : undefined,
                      });
                      setDriveData([]);
                    }}
                  >
                    {submitText || '移到此處'}
                  </Button>
                </div>
              </Dialog.Panel>

            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

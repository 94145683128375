import { z } from 'zod';

export const LINK_FORM_SCHEMA = z
  .object({
    linkType: z
      .string()
      .nullable(),
    link: z
      .string({
        required_error: '必填項目',
      }),
  })
  .refine(
    ({ linkType, link }) => {
      const webReg = /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
      const emailReg = /^[^\s@:%.+~#=$]+@[^\s@]+\.[^\s@:%.+~#=$]{2,5}$/;
      const telReg = /^(\(\+[0-9]{3}\))?\s*?[\d]{1,8}$/;

      const linkReg = () => {
        if (linkType === 'link') {
          return webReg;
        }
        if (linkType === 'email') {
          return emailReg;
        }
        return telReg;
      };

      const regex = new RegExp(linkReg());

      if (link && linkType === 'link') {
        if (regex.test(link)) {
          return true;
        }
        return { message: 'URL 格式有誤', path: 'link' };
      }
      if (link && linkType === 'email') {
        if (regex.test(link)) {
          return true;
        }
        return { message: '電郵格式有誤', path: 'link' };
      }
      if (link && linkType === 'tel') {
        if (regex.test(link)) {
          return true;
        }
        return { message: '電話格式有誤', path: 'link' };
      }
      return true;
    },
  );

export type LinkFormDataType = z.infer<typeof LINK_FORM_SCHEMA>;

export const API_URL = import.meta.env.VITE_REACT_APP_API_URL || '';
export const IMAGE_URL = import.meta.env.VITE_REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 20;
export const TOAST_TIME = 5000;
export const APP_NAME = 'Super Vibes';
export const IMAGE_SIZE_IN_MB = 10;
export const IMAGE_SIZE = 1024 * 1024 * IMAGE_SIZE_IN_MB; // 10Mb
export const VIDEO_SIZE_IN_GB = 1;
export const VIDEO_SIZE = 1024 * 1024 * 1024 * VIDEO_SIZE_IN_GB; // 10Mb
export const EXCEL_SIZE = 1024 * 10000;
export const PDF_VIEWER_URL = import.meta.env.VITE_REACT_PDF_VIEWER_URL
  || 'https://static.appicidea.com/public/pdf/web/viewer.html';
export const WEB_APP_URL = import.meta.env.VITE_REACT_APP_URL || 'https://svgroup-user-v2-dev.appicidea.com';

// versioning
export const VERSION_NUMBER = import.meta.env.VITE_REACT_APP_VERSION || '';
export const CURRENT_ENV = import.meta.env.VITE_REACT_APP_ENV || 'Dev';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useLeadingPathName = () => {
  const [leadingPathName, setLeadingPathName] = useState<string>('');
  const { pathname } = useLocation();

  useEffect(() => {
    const pathName = pathname.split('/')[1];
    setLeadingPathName(pathName);
  }, [pathname]);

  return leadingPathName;
};

import { IMAGE_TIME_OUT, VIDEO_TIME_OUT } from 'app/constants';
import { FileResponse } from 'app/models/Common';

import Axios from './index';

/**
* * Upload and resize image file (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/files/image)
* @params file string ($binary)
+*/

export const uploadImage = async (file: File) => {
  const uploadFormData = new FormData();
  uploadFormData.append('file', file);

  const { data } = await Axios.post<FileResponse>('/files/image', uploadFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: IMAGE_TIME_OUT,
  });
  return data;
};

/**
* * Upload and resize file (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/files/file)
* @params file string ($binary)
+*/

export const uploadFile = async (file: File) => {
  const uploadFormData = new FormData();
  uploadFormData.append('file', file);
  const { data } = await Axios.post<FileResponse>('/files/file', uploadFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: VIDEO_TIME_OUT,
  });
  return data;
};

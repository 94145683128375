import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES, SHOP_MODE_ROUTES } from 'app/constants';
import { useAuthStore, useShopStore } from 'app/stores';

export const HomePage = () => {
  const { profile } = useAuthStore();
  const { shop } = useShopStore();

  const navigateTo = useMemo(() => {
    if (shop?.shopId) {
      return SHOP_MODE_ROUTES(shop.shopId).EVENTS_LIST;
    }
    if (profile?.role !== '超級管理員') {
      return ROUTES.EVENTS_LIST;
    }
    return ROUTES.USERS;
  }, [profile?.role, shop?.shopId]);

  return <Navigate to={navigateTo} replace />;
};

import { TimeStamp } from './Common';

export interface DriveFileResponse {
  type: DocumentType;
  folderType: FolderType;
  driveType: DriveType;
  dir: string;
  fileName: string;
  createdAt: string;
}

export interface DriveShareToken extends TimeStamp {
  driveShareTokenId: number;
  userId: number;
  token: string;
  driveType: DriveType;
  folderType: FolderType;
  path: string;
}

export interface ShareDriveTokenResponse {
  path: string;
  driveType: DriveType;
  shopId?: number | string;
  folderType?: FolderType;
  token?: string;
}

export interface GetOneFileTypeRes {
  type: DocumentType;
  url: string | null;
}

export enum DocumentEnum {
  Folder = 'Folder',
  File = 'File',
}
export type DocumentType = keyof typeof DocumentEnum;

export enum FolderTypeEnum {
  image = '圖片庫',
  video = '影音庫',
  others = '公開文件',
  private = '不公開文件',
}
export type FolderType = keyof typeof FolderTypeEnum;

export enum DriveEnum {
  public = 'public',
  private = 'private',
  shop = 'shop',
}
export type DriveType = keyof typeof DriveEnum;

export enum FileMineEnum {
  image = 'image',
  video = 'video',
  others = 'others',
}
export type FileMimeType = keyof typeof FileMineEnum;

export interface UploadFileItem {
  name: string;
  type: FolderType;
  status: FileUploadStatusType;
  file: File;
}

export type FileUploadStatusType = 'success' | 'fail' | 'uploading';

import { Dialog, Transition } from '@headlessui/react';
import {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { IoClose } from 'react-icons/io5';
import 'swiper/css';
import 'swiper/css/navigation';
import { toast } from 'react-toastify';

import { getOneFileType } from 'app/apis/DriveServices';
import { PDF_VIEWER_URL } from 'app/configs';
import { DriveFileResponse } from 'app/models/Drive';

interface Props {
  visible: boolean;
  data: DriveFileResponse | null;
  onClose: () => void;
}

export const PdfPreview = ({
  visible,
  data,
  onClose,
}: Props) => {
  const [url, setUrl] = useState<string | null>(null);
  const [fail, setFail] = useState<boolean>(false);
  const getFileType = useCallback(async () => {
    if (data) {
      try {
        const res = await getOneFileType({
          folderType: data.folderType,
          driveType: data.driveType,
          path: data.dir === '' ? data.fileName : `${data.dir}/${data.fileName}`,
        });
        setUrl(res.url);
      } catch (error) {
        setFail(true);
        toast.error('未能獲取檔案');
      }
    }
  }, [data]);

  useEffect(() => {
    getFileType();
  }, [getFileType]);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/85" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-screen min-w-[100vw] text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex h-screen w-screen flex-col align-middle shadow-xl transition-all">
                <div className="fixed top-0 z-20 flex w-full justify-center py-[20px]">
                  <button
                    type="button"
                    className="absolute right-0 top-0 p-[20px]"
                    onClick={onClose}
                  >
                    <IoClose className="size-[25px]" color="#A7A7A7" />
                  </button>
                </div>

                <iframe
                  src={`${PDF_VIEWER_URL}?file=${url}`}
                  title="pdf"
                  className="w-full flex-1 p-[36px] pb-0"
                />

                {fail
                  ? <div className="absolute inset-1/2 translate-x-1/2 translate-y-1/2 text-white">Failed</div>
                  : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

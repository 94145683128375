import { Popover, Transition } from '@headlessui/react';
import {
  Fragment, MouseEventHandler, useMemo, useState,
} from 'react';
import { BiShare } from 'react-icons/bi';
import { FcFolder } from 'react-icons/fc';
import { FiEdit } from 'react-icons/fi';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { MdOutlineDriveFileMove } from 'react-icons/md';
import { TiFolderOpen } from 'react-icons/ti';
import { toast } from 'react-toastify';

import { getDriveFileURL, moveRenameDocument } from 'app/apis/DriveServices';
import { fileOnRename, shareOnPress } from 'app/helpers/Drive';
import { DriveFileResponse } from 'app/models/Drive';
import cn from 'app/utils/ClassName';

import Button from '../Button';

import { DriveFolderFormModal } from './DriveFolderFormModal';
import { DriveMoveToModal } from './DriveMoveToModal';

interface Props {
  data: DriveFileResponse;
  isSelected: boolean;
  isListView: boolean;
  shopId?: number | string;
  itemOnClick: MouseEventHandler<HTMLButtonElement>;
  onFolderOpen: () => void;
  getDriveData: () => Promise<void>;
}

export const DriveFolderItem = ({
  data,
  isSelected,
  isListView,
  shopId,
  itemOnClick,
  onFolderOpen,
  getDriveData,
}: Props) => {
  const [fail, setFail] = useState<boolean>(false);
  const [renameModalVisible, setRenameModalVisible] = useState<boolean>(false);
  const [moveToModalVisible, setMoveToModalVisible] = useState<boolean>(false);
  const [moveToModalLoading, setMoveToModalLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    dir,
    fileName,
    folderType,
    driveType,
  } = data;

  const coverIcon = useMemo(
    () => getDriveFileURL({
      folderType: folderType !== 'private' ? folderType : undefined,
      driveType: shopId ? 'shop' : 'public',
      shopId,
      path: dir === ''
        ? `.${fileName}`
        : `${dir}/.${fileName}`,
    }),
    [dir, fileName, folderType, shopId],
  );

  return (
    <button
      className={cn(
        'flex items-center gap-[4px] border',
        isListView ? 'p-[10px]' : 'flex-col rounded-tr-[10px]',
        isListView && !isSelected && 'border-transparent border-b-border-brand',
        isSelected && 'bg-brand-secondary/30',
      )}
      type="button"
      onClick={itemOnClick}
    >
      <div className={cn('relative', !isListView && 'flex w-full items-center justify-center border-b bg-white rounded-tr-[10px]')}>
        <FcFolder className={cn(isListView ? 'size-[18px]' : 'size-[150px]')} />
        <div className={cn(isListView || fail ? 'hidden' : 'absolute bottom-[10px] mx-auto translate-x-[24px] border-[4px] border-white shadow-[0_1.12777px_28.1942px_0_rgba(0,0,0,0.2)]')}>
          <img
            src={coverIcon}
            alt="cover"
            className={cn('w-[130px] aspect-[16/9] object-cover', fail && 'hidden')}
            onError={() => {
              setFail(true);
            }}
          />
        </div>
      </div>
      <div className={cn('flex w-full flex-1 items-center justify-between', !isListView && 'px-[10px] py-[5px]')}>
        <div className="line-clamp-2">{fileName}</div>

        <Popover className="relative">
          <Popover.Button
            as={Button}
            variant="text"
            color="primary"
            containerStyle="flex size-[38px] items-center justify-center rounded-full"
            onClick={(e) => {
              // e.preventDefault();
              e.stopPropagation();
            }}
          >
            <HiOutlineDotsVertical />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className="absolute right-0 top-[48px] z-[5] min-w-[160px] bg-white text-[12px] shadow-card"
            >
              {({ close }) => (
                <>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      onFolderOpen();
                      close();
                    }}
                  >
                    <TiFolderOpen />
                    <div className="text-text-primary">打開</div>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRenameModalVisible(true);
                      close();
                    }}
                  >
                    <FiEdit />
                    <div className="text-text-primary">更改名稱及封面</div>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      shareOnPress(
                        {
                          folderType,
                          driveType,
                          shopId,
                          path: dir === ''
                            ? `${fileName}`
                            : `${dir}/${fileName}`,
                        },
                      );
                      close();
                    }}
                  >
                    <BiShare />
                    <div className="text-text-primary">分享資料夾</div>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMoveToModalVisible(true);
                      close();
                    }}
                  >
                    <MdOutlineDriveFileMove />
                    <div className="text-text-primary">移至</div>
                  </Button>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>

        <DriveFolderFormModal
          visible={renameModalVisible}
          loading={loading}
          onClose={() => { setRenameModalVisible(false); }}
          initialValue={{
            name: fileName,
            image: fail ? [] : [{
              name: fileName,
              preview: coverIcon,
            }],
          }}
          onSubmit={async ({ name, image }) => {
            try {
              setLoading(true);
              await fileOnRename(data, image, name, !fail, shopId);
              await getDriveData();
              toast.success('更改名稱成功');
            } catch {
              toast.error('更改名稱失敗');
            } finally {
              setLoading(false);
            }
          }}
        />

        <DriveMoveToModal
          visible={moveToModalVisible}
          loading={moveToModalLoading}
          shopId={shopId}
          onClose={() => { setMoveToModalVisible(false); }}
          onSubmit={async (newDir) => {
            try {
              setMoveToModalLoading(true);
              await moveRenameDocument({
                source: {
                  folderType,
                  driveType,
                  shopId,
                  path: `${dir}/${fileName}`,
                },
                dest: {
                  ...newDir,
                  shopId,
                  path: `${newDir.path}/${fileName}`,
                },
              });
              toast.success('移動成功');
              setMoveToModalVisible(false);
              getDriveData();
            } catch {
              toast.error('移動失敗');
            } finally {
              setMoveToModalLoading(false);
            }
          }}
        />
      </div>
    </button>
  );
};

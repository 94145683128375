import { MdOutlineManageSearch } from 'react-icons/md';

interface Props {
  title?: string;
  pageName?: string;
}

export const NoData = ({ title, pageName }: Props) => (
  <div className="flex size-full flex-col items-center justify-center bg-white">
    <MdOutlineManageSearch className="size-20 text-text-light" />
    <div className="text-[18px] font-semibold text-text-light">{title || `找不到${pageName || ''}資料`}</div>
  </div>
);
